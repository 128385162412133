import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service2.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForNeurologicalConditions = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Lives with NDIS Neurological Physiotherapy</h1>
                    <h2>Explore how NDIS-funded physiotherapy can transform the lives of individuals with neurological conditions at Ramp Physio and Fitness.</h2>
                    <p>Neurological conditions can have a profound impact on an individual’s mobility, strength, and independence. Ramp Physio and Fitness is dedicated to providing specialized NDIS physiotherapy services to support those living with neurological conditions. Our goal is to empower our clients by enhancing their physical capabilities, fostering independence, and improving their overall quality of life. In this comprehensive guide, we will delve into the benefits of NDIS physiotherapy for neurological conditions, and how our expert team can assist you or your loved ones in navigating this journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Neurological Disorders</h1>
                        <p>Neurological disorders encompass a variety of conditions that affect the brain, spinal cord, and nerves. Common conditions include multiple sclerosis, Parkinson’s disease, stroke, and traumatic brain injuries. These disorders can lead to a range of symptoms such as muscle weakness, loss of balance, impaired coordination, and difficulties with walking and movement.</p>

                        <p>At Ramp Physio and Fitness, we begin with a thorough assessment to understand the specific challenges faced by each client. Our NDIS physiotherapy services are then tailored to meet the individual needs of our clients, with a focus on maximizing function and enhancing quality of life.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_1458512271.webp" alt="Neurological Physiotherapy" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Massage" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Physiotherapy Programs</h1>
                    <p>Each client's neurological condition presents uniquely, which is why our NDIS physiotherapy programs are customized for the individual. Our physiotherapists utilize a variety of techniques including strength training, balance exercises, and functional mobility training to address specific deficits and promote neurological recovery.</p>

                    <p>Our programs are designed with a holistic approach, considering not just the physical symptoms but also the psychological and social aspects of living with a neurological condition. We work closely with clients, their families, and support teams to create a supportive and effective rehabilitation environment.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Enhancing Mobility and Independence</h1>
                    <p>Improving mobility is a key focus of NDIS physiotherapy. Our interventions aim to increase strength and flexibility, improve gait patterns, and reduce the risk of falls. We utilize evidence-based practices to help clients regain as much independence as possible, enabling them to participate more fully in daily activities and community life.</p>

                    <p>Our team is experienced in the use of assistive devices and can provide training on their use to further enhance mobility and safety. We also offer guidance on home modifications and lifestyle adaptations to support clients’ independence.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Optimizing Neuroplasticity and Recovery</h1>
                        <p>Neuroplasticity refers to the brain's ability to reorganize itself by forming new neural connections. Our NDIS physiotherapy services harness this principle by providing repetitive, task-specific training that encourages the brain to adapt and learn new ways of performing movements.</p>
                        <p>Through consistent practice and expert guidance, clients can experience improvements in function and potentially recover abilities that were previously lost or impaired due to their neurological condition.</p>

                        <br></br>
                        <p>
                            Our commitment at Ramp Physio and Fitness is to provide a pathway for healing and empowerment through physiotherapy. We are dedicated to supporting our clients as they work towards their personal health and mobility goals, with the NDIS serving as a valuable resource in their rehabilitation journey.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForNeurologicalConditions;