import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const Willmyndisphysiotherapistgivemeexercisestodoathome = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering NDIS Participants: Home Exercise Programs</h1>
                    <h2>Discover how our NDIS physiotherapists at Ramp Physio and Fitness can tailor home exercise programs to enhance your rehabilitation journey.</h2>
                    <p>At Ramp Physio and Fitness, we firmly believe in the power of personalized physiotherapy to transform the lives of our NDIS participants. As part of our comprehensive service, our NDIS physiotherapists are dedicated to devising home exercise programs that complement in-clinic treatments. These programs are designed to empower you to take charge of your rehabilitation, improve functional independence, and achieve your health and fitness goals. In this article, we will explore how at-home exercises play a crucial role in the continuum of care for NDIS participants.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding the Role of At-Home Exercises</h1>
                        <p>At-home exercises are an extension of the therapy you receive within our clinic. These exercises are vital for maintaining the progress you make during your sessions and for continuing to develop strength, flexibility, and endurance. Our NDIS physiotherapists ensure that each exercise is tailored to your specific needs, taking into account your abilities, limitations, and personal objectives.</p>
                        <p>Home exercise programs may include a variety of activities such as stretches, strengthening exercises, balance training, and functional movements. These exercises are designed to be safely performed in the comfort of your home with minimal equipment, maximizing convenience and adherence.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_679609972.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Creating a Customized Exercise Plan</h1>
                    <p>Your NDIS home exercise program begins with a thorough assessment by one of our qualified physiotherapists. This assessment allows us to understand your physical capabilities, identify areas that require improvement, and set realistic goals. We then craft a customized exercise plan that aligns with your NDIS plan and personal aspirations.</p>
                    <p>Our physiotherapists will demonstrate each exercise, ensuring you understand the proper technique and the intended outcome. We also provide clear instructions and resources, such as written descriptions and video demonstrations, to support you in performing these exercises correctly and safely at home.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Benefits of At-Home Physiotherapy Exercises</h1>
                    <p>Engaging in a home exercise program offers multiple benefits for NDIS participants. It not only accelerates the recovery process but also encourages self-management and independence. Regularly performing prescribed exercises can lead to improved mobility, reduced pain, and a greater sense of well-being.</p>
                    <p>Additionally, at-home exercises can help to prevent the recurrence of injuries by strengthening the body and improving functional capacity. Participants often experience an enhanced quality of life as they gain the ability to perform daily activities with greater ease and confidence.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Monitoring Progress and Making Adjustments</h1>
                    <p>Our commitment to your rehabilitation journey extends beyond the walls of our clinic. We regularly monitor your progress and make necessary adjustments to your home exercise program. This ensures that the exercises remain challenging yet achievable, promoting continuous improvement.</p>
                    <p>During follow-up consultations, either in person or via telehealth, we gather feedback on your experience with the exercises and address any concerns. Our goal is to maintain an open line of communication, providing you with support and encouragement every step of the way.</p>
                    <br></br>
                    <p>
                        In summary, at Ramp Physio and Fitness, we are dedicated to providing NDIS participants with comprehensive physiotherapy services that include personalized home exercise programs. Our team of expert physiotherapists is committed to working closely with you to achieve your rehabilitation goals and enhance your overall quality of life. If you're ready to embark on a journey toward better health and fitness, reach out to us and discover how we can support you in reaching your full potential.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Willmyndisphysiotherapistgivemeexercisestodoathome;