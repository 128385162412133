import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import physioLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForSportsInjuryPrevention = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Mastering Sports Injury Prevention</h1>
                    <h2>Unlock your athletic potential with our physiotherapy treatment and exercises designed to prevent sports injuries.</h2>
                    <p>Ramp Physio and Fitness is dedicated to helping athletes of all levels prevent sports injuries through expert physiotherapy treatment and exercises. Our team in Homebush, NSW, uses evidence-based strategies to enhance your performance and keep you in the game. In this guide, we'll share insights into the best practices for sports injury prevention and how our tailored programs can help safeguard your athletic pursuits.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>

            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Injury Mechanics</h1>
                        <p>Knowing how injuries occur is the first step in prevention. Sports injuries typically result from overuse, direct impact, or the application of force that is greater than the body part can structurally withstand. Common examples include sprains, strains, knee injuries, swollen muscles, Achilles tendon injuries, pain along the shin bone, fractures, and dislocations.</p>

                        <p>Our physiotherapists begin with an assessment of your risk factors, such as previous injuries, training habits, and biomechanics. From here, we develop a prevention-focused training plan that emphasizes proper technique, equipment use, and progressive loading to minimize injury risk.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_679609972.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>

            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Key Exercises for Prevention</h1>
                        <p>Exercise is a cornerstone of injury prevention. Our physiotherapy programs include exercises that improve strength, flexibility, and balance. Here are 10 examples of exercises we might incorporate into your routine:</p>
                        <p>• Dynamic warm-ups to prepare the body for activity</p>
                        <p>• Plyometrics to enhance explosive power and agility</p>
                        <p>• Balance exercises to improve proprioception and stability</p>
                        <p>• Core strengthening for better posture and alignment</p>
                        <p>• Resistance training to build supportive muscle tissue</p>
                        <p>• Stretching routines to maintain flexibility</p>
                        <p>• Joint stabilization exercises, particularly for the knees and ankles</p>
                        <p>• Eccentric exercises for tendon health</p>
                        <p>• Cardiovascular conditioning for endurance and recovery</p>
                        <p>• Sport-specific drills to simulate game scenarios</p>
                    </div>
                </div>
            </SkewedContainer>

            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={physioLogo} alt="Physio Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Techniques for Optimal Recovery</h1>
                    <p>Recovery is just as crucial as training when it comes to injury prevention. Our physiotherapy approach includes techniques such as:</p>
                    <p>• Manual therapy to reduce muscle tension and promote healing</p>
                    <p>• Soft tissue release to improve mobility and decrease scar tissue</p>
                    <p>• Joint mobilization to increase range of motion</p>
                    <p>• Cryotherapy and thermotherapy for pain management and inflammation reduction</p>
                    <p>• Compression therapy to support lymphatic drainage and circulation</p>
                    <p>• Taping and bracing to protect injured areas during recovery</p>
                    <p>• Education on sleep hygiene and nutrition for optimal body function</p>
                    <p>• Stress management techniques to reduce the risk of tension-related injuries</p>
                    <p>• Personalized home exercise programs to continue progress outside the clinic</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>

            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Comprehensive Support System</h1>
                        <p>At Ramp Physio and Fitness, we believe that a supportive environment is key to successful injury prevention. We provide:</p>
                        <p>• A team approach where physiotherapists, trainers, and clients collaborate</p>
                        <p>• Regular assessments to monitor progress and adjust programs as needed</p>
                        <p>• Access to state-of-the-art gym facilities for a variety of training modalities</p>
                        <p>• A focus on mental resilience and confidence building through rehabilitation</p>
                        <p>• A commitment to educating our clients on the principles of injury prevention</p>
                        <p>• A welcoming community that encourages accountability and motivation</p>
                        <br></br>
                        <p>
                            In conclusion, Ramp Physio and Fitness offers a comprehensive approach to physiotherapy treatment and exercises for sports injury prevention. Our tailored programs, expert guidance, and supportive community are here to keep you active, healthy, and injury-free. Connect with us to start your journey toward optimal athletic performance and prevention of sports injuries.
                        </p>
                    </div>
                </div>
            </SkewedContainer>

            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForSportsInjuryPrevention;