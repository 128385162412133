import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';
import './News.css';

const UnderstandingPhysiotherapy = () => {
  const articles = [
    { title: 'Understanding and Treating Osgood-Schlatter Disease', path: '/news/understandingandtreatingosgoodschlatterdisease' },
    { title: 'Understanding Biofeedback in Physiotherapy', path: '/news/understandingbiofeedbackinphysiotherapy' },
    { title: 'Understanding Biomechanics in Sports Physiotherapy', path: '/news/understandingbiomechanicsinsportsphysiotherapy' },
    { title: 'Understanding Craniosacral Therapy in Physiotherapy', path: '/news/understandingcraniosacraltherapyinphysiotherapy' },
    { title: 'Understanding Myofascial Release Techniques', path: '/news/understandingmyofascialreleasetechniques' },
    { title: 'Understanding Vestibular Disorders and Physiotherapy Treatments', path: '/news/understandingvestibulardisordersandphysiotherapytreatments' },
    { title: 'The Benefits of Functional Movement Screening', path: '/news/thebenefitsoffunctionalmovementscreening' },
    { title: 'The Benefits of Isokinetic Testing in Rehabilitation', path: '/news/thebenefitsofisokinetictestinginrehabilitation' },
    { title: 'The Importance of Gait Analysis in Physiotherapy', path: '/news/theimportanceofgaitanalysisinphysiotherapy' },
    { title: 'The Role of Orthotics in Physiotherapy', path: '/news/theroleoforthoticsinphysiotherapy' },
    { title: 'The Role of Physiotherapy in Managing Diabetes', path: '/news/theroleofphysiotherapyinmanagingdiabetes' },
    { title: 'Benefits of Proprioceptive Training in Injury Prevention', path: '/news/benefitsofproprioceptivetrainingininjuryprevention' },
    { title: 'Neuromuscular Electrical Stimulation in Rehabilitation', path: '/news/neuromuscularelectricalstimulationinrehabilitation' },
    { title: 'Your Local Gym', path: '/news/gymnearme' },
    { title: 'Homebush Physiotherapy', path: '/news/homebushphysiotherapy' },
    { title: 'NDIS Physiotherapy', path: '/news/ndisphysiotherapy' },
    { title: 'Musculoskeletal Physiotherapy', path: '/news/musculoskeletalphysiotherapy' },
    { title: 'Neurological Physiotherapy', path: '/news/neurologicalphysiotherapy' },
    { title: 'Physiotherapy Clinic Near Me', path: '/news/physiotherapyclinicnearme' },
    { title: 'Physiotherapy Exercises', path: '/news/physiotherapyexercises' },
    { title: 'Physiotherapy in Occupational Health', path: '/news/physiotherapyinoccupationalhealth' },
    { title: 'Remedial Massage for Back Pain', path: '/news/remedialmassageforbackpain'},
    { title: 'Remedial Massage Therapy', path: '/news/remedialmassagetherapy' },
    { title: 'Normatec 3', path: '/news/normatec3' },
    { title: 'Normatec Compression', path: '/news/normateccompression' },
  ];

  const [searchQuery, setSearchQuery] = useState('');

  const fuse = new Fuse(articles, {
    keys: ['title'],
    threshold: 0.3,
  });

  const results = searchQuery ? fuse.search(searchQuery) : articles;
  const filteredArticles = searchQuery ? results.map(result => result.item) : articles;

  const half = Math.ceil(filteredArticles.length / 2);
  const firstColumnArticles = filteredArticles.slice(0, half);
  const secondColumnArticles = filteredArticles.slice(half);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="news-section">
      <div className="news-header">
        <h2>Understanding Physiotherapy</h2>
        <p>
          Stay informed with the latest news and updates from Ramp Physio and Fitness. Whether it's new services, health tips, or community events, you'll find it all here.
        </p>
        <input
          type="text"
          placeholder="Search articles..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="news-search"
        />
      </div>
      <div className="news-content">
        {/* First Column */}
        <div className="news-column">
          {firstColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < firstColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

        {/* Second Column */}
        <div className="news-column">
          {secondColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < secondColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UnderstandingPhysiotherapy;
