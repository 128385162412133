import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import arthritisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForArthritis = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Movement: Arthritis Management</h1>
                    <h2>Discover how physiotherapy treatment and exercises can alleviate arthritis symptoms and improve quality of life at Ramp Physio and Fitness.</h2>
                    <p>Arthritis is a common condition characterized by joint pain, stiffness, and reduced mobility. At Ramp Physio and Fitness, we are dedicated to empowering individuals with arthritis to lead active and fulfilling lives. Our expert physiotherapists employ a range of treatments and exercises specifically designed to reduce pain, enhance joint function, and improve overall well-being. In this comprehensive guide, we will delve into the benefits of physiotherapy for arthritis and provide practical strategies to manage this chronic condition.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Arthritis and Its Impact</h1>
                        <p>The term arthritis encompasses a range of joint disorders, with osteoarthritis and rheumatoid arthritis being the most prevalent. These conditions can lead to significant discomfort and disability if left unmanaged. A thorough understanding of arthritis and its effects on the body is crucial for effective treatment and self-management. Our physiotherapy assessments aim to identify the specific type of arthritis and the joints involved to tailor a personalized rehabilitation plan.</p>
                        <p>Key factors in our assessment include the severity of joint damage, the level of pain experienced, and the impact on daily activities. Recognizing these elements allows us to address arthritis holistically, considering both the physical and emotional aspects of the condition.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_679609972.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Personalized Physiotherapy Techniques</h1>
                        <p>Our approach to managing arthritis involves a combination of manual therapy techniques and individualized exercise programs. Manual therapy may include joint mobilizations to improve range of motion and soft tissue techniques to reduce muscle tension. These interventions are complemented by specific exercises that focus on strengthening the muscles around affected joints, enhancing stability, and improving mobility.</p>
                        <p>Exercise is a cornerstone of arthritis management. Our physiotherapists will guide you through a series of low-impact exercises designed to minimize joint stress while promoting physical fitness. The goal is to find a balance between rest and activity to avoid exacerbating arthritis symptoms.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container3">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content3">
                        <h1>Exercise Strategies for Arthritis Patients</h1>
                        <p>Exercise recommendations for arthritis patients are carefully crafted to align with individual capabilities and limitations. Our physiotherapists will provide you with a structured exercise regimen that may include:</p>
                        <p>
                            - Range of motion exercises to maintain joint flexibility<br />
                            - Strengthening exercises to support the joints<br />
                            - Aerobic activities to promote cardiovascular health<br />
                            - Balance exercises to reduce the risk of falls<br />
                            - Functional training to ease daily activities<br />
                            - Hydrotherapy for a gentle approach to exercise<br />
                        </p>
                        <p>Adherence to the exercise plan is essential for successful management of arthritis symptoms. We will work closely with you to ensure the exercises are both effective and enjoyable, fostering long-term commitment to your health.</p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container4">
                    <img src={arthritisLogo} alt="Arthritis Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container5">
                <div className="service-intro-content5">
                    <h1>Adaptive Equipment and Assistive Devices</h1>
                    <p>For some individuals with arthritis, the use of adaptive equipment and assistive devices can be life-changing. These tools are designed to reduce strain on affected joints and enhance functional independence. Our physiotherapists can recommend and teach you how to use:</p>
                    <p>
                        - Orthotic inserts to improve foot and ankle alignment<br />
                        - Braces or splints for joint support<br />
                        - Walking aids such as canes or walkers<br />
                        - Ergonomic tools for work and home modifications<br />
                        - Adaptive kitchen gadgets to facilitate meal preparation<br />
                        - Reachers and grabbers to avoid unnecessary bending or reaching<br />
                    </p>
                    <p>Integrating these devices into your daily routine can make a significant difference in managing arthritis symptoms and maintaining an active lifestyle.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container6">
                    <div className="service-intro-content6">
                        <h1>Educating Patients for Self-Management</h1>
                        <p>Education plays a vital role in the self-management of arthritis. Our physiotherapists provide you with the knowledge and resources needed to understand your condition and take control of your health. Topics covered may include:</p>
                        <p>
                            - The nature and progression of arthritis<br />
                            - Pain management techniques<br />
                            - The importance of maintaining a healthy weight<br />
                            - Strategies to protect your joints during activities<br />
                            - Stress reduction methods<br />
                            - Nutritional advice to support joint health<br />
                        </p>
                        <p>By equipping you with this information, we empower you to make informed decisions about your treatment and lifestyle choices.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_679609972.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForArthritis;