import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
import { Link } from 'react-router-dom';

const NDIS = () => {

    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}
            <div className="service-detail-content">
                <div className="service-detail-text">
                <br></br><br></br>
                    <h1>NDIS</h1>
                    <h2>Registered NDIS Provider</h2>
                    <p>
                       At Ramp Physio and Fitness, we are proud to be registered providers under the National Disability Insurance Scheme (NDIS). The NDIS is an Australian government initiative that supports individuals with disabilities by providing funding for various services, including physiotherapy. Our goal is to help you achieve the best possible health outcomes through personalized care tailored to your unique needs
                    </p>
                    <p>Sign up with your email address to receive news and updates.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Our Comprehensive NDIS Physiotherapy Services</h1>
                        <p>Looking for expert NDIS physiotherapy services? At Ramp Physio, our skilled team offers personalized care wherever you need it most—whether it's your home, workplace, school, or another convenient location.</p>
                        <br></br>
                        <p>We pride ourselves on flexibility, with no waiting lists, ensuring you can start your NDIS physiotherapy journey without delay. As an NDIS-registered provider, we help participants access vital services through the National Disability Insurance Scheme (NDIS), which supports individuals living with disabilities and their families. The NDIS covers a variety of essential services, from therapeutic supports and transportation to home and vehicle modifications, employment assistance, and health and wellbeing programs.</p>
                        <br></br>
                        <p>At Ramp Physio, our mobile physiotherapy services deliver expert care and treatment directly to you, helping you improve mobility, function, and overall wellbeing. We offer comprehensive support in navigating your NDIS plan, ensuring you get the answers and assistance you need for optimal participant management and recovery.</p>
                        <p>Our compassionate team is dedicated to empowering you to live life to the fullest, with tailored care that makes a difference every step of the way.</p>
                        <br></br>
                        <p>We offer a wide range of physiotherapy services under the NDIS, designed to enhance your mobility, strength, and overall well-being. Whether you need assistance with chronic pain management, rehabilitation after an injury, or ongoing support to improve your daily life, our experienced physiotherapists are here to help.
                        Our services include:

                        <li>Personalized exercise programs</li>
                        <li>Mobility and gait training</li>
                        <li>Pain management strategies</li>
                        <li>Post-surgery rehabilitation</li>
                        <li>Hydrotherapy sessions</li>
                        Each service is designed to align with your NDIS goals and is delivered with compassion and expertise.</p>
                        <p><a href="/news/keytreatmentsandcommonquestions">Learn more about NDIS Physiotherapy: Key Treatments and Common Questions →</a></p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Why Choose Ramp Physio and Fitness for Your NDIS Needs?</h1>
                    <p>Choosing the right physiotherapist under the NDIS is crucial to achieving your health goals. At Ramp Physio and Fitness, we bring extensive experience, specialized training, and a deep understanding of the NDIS framework to every client we serve. Our team is committed to providing care that is not only effective but also empowering, helping you gain confidence in your abilities and improve your quality of life.
                    What sets us apart:

                    <li>NDIS registered and experienced physiotherapists</li>
                    <li>Tailored treatment plans</li>
                    <li>Holistic approach to health and wellness</li>
                    <li>State-of-the-art facilities</li>
                    <li>Strong focus on client satisfaction</li></p><br/>
                    <p>Common questions about NDIS physiotherapy:
                    <li><a href="/news/howoftenwillineedtoseemyndisphysio">How often will I need to see my NDIS physio?</a></li>
                        <li><a href="/news/whatdoesatypicalndisphysiosessionlooklike">What does a typical NDIS physio session look like?</a></li>
                        <li><a href="/news/willmyndisphysiotherapistgivemeexercisestodoathome">Will my NDIS physiotherapist give me exercises to do at home?</a></li>
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <h2 className="service-intro-text">
                        Ramp Physio and Fitness offers NDIS Physiotherapy and Gym services and support to participants with a wide range of disabilities, including:
                    </h2>
                    
                    <div className="news-content">
                        {/* First Column */}
                        <div className="news-column">
                            <div className="news-item">
                                <Link to="/news/frequentlyaskedquestions">
                                    <p>Frequently Asked Questions</p>
                                </Link>
                            </div>
                            <div className="news-item">
                                <Link to="/news/intellectualanddevelopmental">
                                    <p>Intellectual and Developmental Disorders</p>
                                </Link>
                            </div>
                            <div className="news-item">
                                <Link to="/news/mentalhealth">
                                    <p>Mental Health Conditions</p>
                                </Link>
                            </div>
                        </div>

                        {/* Second Column */}
                        <div className="news-column">
                            <div className="news-item">
                                <Link to="/news/neurologicalconditions">
                                    <p>Neurological Conditions</p>
                                </Link>
                            </div>
                            <div className="news-item">
                                <Link to="/news/neuromuscularandchronic">
                                    <p>Neuromuscular and Chronic Conditions</p>
                                </Link>
                            </div>
                            <div className="news-item">
                                <Link to="/news/keytreatmentsforndis">
                                    <p>Physiotherapy Treatments and Techniques</p>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Get Started with Your NDIS Physiotherapy Journey Today</h1>
                    <p>Ready to take the next step in your health journey? Contact Ramp Physio and Fitness today to learn more about how our NDIS physiotherapy services can support your goals. Whether you are just starting with the NDIS or looking to enhance your current care plan, we are here to guide you every step of the way.</p>
                    <p>Send us a message or call us at [8188 1618] to book your initial consultation. Let us help you achieve the mobility, strength, and independence you deserve.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
            <HealthSection />
            </SkewedContainer>
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
