import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForNdisPhysiotherapy = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy Services - Ramp Physio and Fitness</title>
                <meta name="description" content="NDIS Physiotherapy at Ramp Physio and Fitness provides tailored rehabilitation programs to support individuals with disabilities in achieving their health and fitness goals." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering NDIS Participants through Physiotherapy</h1>
                    <h2>Discover the transformative benefits of NDIS Physiotherapy services offered by Ramp Physio and Fitness, where we prioritize your unique health and fitness journey.</h2>
                    <p>Ramp Physio and Fitness is a proud provider of NDIS Physiotherapy services in Homebush, NSW. We are dedicated to empowering NDIS participants with personalized physiotherapy programs that cater to their specific needs. Our expert therapists are committed to helping you navigate your health and fitness journey, ensuring you achieve your goals and enhance your quality of life.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding NDIS Physiotherapy</h1>
                        <p>NDIS Physiotherapy is an essential service designed to support individuals with disabilities through therapeutic interventions. It aims to foster independence, enhance mobility, and improve overall physical function. Our services are centered around the National Disability Insurance Scheme (NDIS), which provides the necessary funding for eligible participants to access high-quality physiotherapy care.</p>
                        <p>At Ramp Physio and Fitness, we begin with an in-depth assessment to understand your personal objectives, challenges, and the impact of your disability. This initial evaluation is crucial for developing a customized physiotherapy plan that aligns with your NDIS goals.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_679609972.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Personalized Treatment Approaches</h1>
                        <p>Our NDIS Physiotherapy services are tailored to meet the individual needs of each participant. We incorporate a range of therapeutic techniques, including manual therapy, exercise prescription, and functional training, to address your specific health concerns. Our approach is not one-size-fits-all; it's a personalized pathway to improve your physical capabilities and support your participation in everyday activities.</p>
                        <p>Our physiotherapists work closely with you to set realistic milestones, track your progress, and adjust your therapy as needed. We understand that every individual's journey is unique, and we are here to provide the support and expertise required for a successful rehabilitation experience.</p>
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container3">
                <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                <p className="service-intro-text">
                    Ramp Physio and Fitness
                </p>
                <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="servicedetail-book-now"
                >
                    Book Now
                </a>
            </div>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Comprehensive Support and Guidance</h1>
                    <p>Our commitment to your well-being extends beyond the treatment sessions. We offer comprehensive support and guidance to ensure you can navigate the NDIS process with ease. From helping you understand your funding options to communicating with NDIS planners and coordinators, we are here to assist you every step of the way.</p>
                    <p>Ramp Physio and Fitness is not just about providing physiotherapy; it's about building a partnership with you to ensure your journey towards better health and fitness is smooth and successful. We are here to empower you to live your best life.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Engaging in Active Rehabilitation</h1>
                    <p>Active rehabilitation is a cornerstone of our NDIS Physiotherapy services. We believe in the importance of engaging you in your recovery process. Our gym and personal training facilities are equipped to support a range of physical activities that are not only therapeutic but also enjoyable.</p>
                    <p>Our experienced trainers work alongside physiotherapists to ensure that your exercise program is safe, effective, and aligned with your physiotherapy goals. This integrated approach helps to maximize your rehabilitation potential and promotes long-term health benefits.</p>
                    <br></br>
                    <p>
                        In summary, our NDIS Physiotherapy services at Ramp Physio and Fitness are designed to provide you with the highest level of care and support. We are committed to helping you achieve your health and fitness aspirations by offering expert guidance, personalized treatment plans, and a supportive environment. Contact us today to start your journey towards a more active and fulfilling life.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForNdisPhysiotherapy;