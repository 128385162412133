import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
const NDIS = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>NDIS Physiotherapy Services: Empowering Your Journey to Wellness</h1>
                    <h2>Discover how NDIS physiotherapy services at Ramp Physio and Fitness can revolutionize your rehabilitation experience, offering personalized care and support for a more independent, fulfilling life.</h2>
                    <p>Welcome to Ramp Physio and Fitness, where our commitment to your health and wellbeing goes beyond the conventional. As a dedicated physiotherapist, I understand that navigating the path to recovery, especially within the framework of the National Disability Insurance Scheme (NDIS), can be complex. Our tailored NDIS physiotherapy services are designed to empower you, providing expert care that addresses your unique needs and goals. Let's explore how our physiotherapy support can transform your life, one step at a time.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Holistic Approach to NDIS Physiotherapy</h1>
                        <p>At Ramp Physio and Fitness, we believe in a holistic approach to physiotherapy under the NDIS. Our team of experienced physiotherapists works closely with participants to create a comprehensive plan that addresses not just physical ailments but also the psychological and social aspects of living with a disability. We recognize each individual's journey is unique, and our goal is to enhance your ability to engage in everyday activities, improve your mobility, and support your independence.</p>
                        <p>Our physiotherapy services are person-centered, meaning we listen to your personal objectives and tailor our treatment to help you reach them. We collaborate with other healthcare professionals and NDIS coordinators to ensure a seamless integration of services, making your road to recovery as smooth as possible.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                        <h1>Chronic Pain Management Strategies</h1>
                        <p>Living with chronic pain can be debilitating and demoralizing, but at Ramp Physio and Fitness, we're equipped to help you manage and alleviate your discomfort. Our physiotherapists are skilled in various techniques that can reduce pain levels, enhance function, and improve quality of life. We understand the complexities of chronic pain and how it affects every aspect of your life.</p>
                        <p>Through a combination of manual therapy, exercise prescription, and education, we empower you to take control of your pain. Our treatment plans are designed with your input, ensuring they resonate with your lifestyle and preferences. We're committed to providing you with the tools and support necessary to manage your pain effectively and regain the joys of daily life.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                        <h1>Empowering Through Personalized Training</h1>
                        <p>At Ramp Physio and Fitness, personalized training is at the heart of our physiotherapy services. We know that every NDIS participant has different physical capabilities and goals, and our training programs reflect that diversity. Our physiotherapists are not just health professionals; they are coaches and motivators who will work with you to strengthen your body, boost your confidence, and enhance your overall fitness.</p>
                        <p>Our training programs are carefully crafted to be both challenging and achievable, pushing you to excel while ensuring your safety and well-being. Whether it's improving your balance, building muscle strength, or increasing your endurance, our personalized training is a critical component of your rehabilitation and a testament to our commitment to your progress.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Group Training Classes for Community Connection</h1>
                    <p>Rehabilitation can be a lonely process, but at Ramp Physio and Fitness, we strive to foster a sense of community through our group training classes. These classes are more than just a means to physical rehabilitation; they're a way to connect with others who share similar experiences and challenges. Under the guidance of our skilled physiotherapists, these classes provide a supportive environment where you can work on your recovery while building relationships.</p>
                    <p>Our group training classes are designed to accommodate different abilities and are aligned with NDIS goals, ensuring that you receive the social, emotional, and physical benefits of group exercise. By participating, you're not just improving your physical health; you're becoming part of a community that celebrates each milestone with you.</p>
                    <br></br>
                    <p>
                        At Ramp Physio and Fitness, our NDIS physiotherapy services are more than just treatments; they're a partnership between you and our team, dedicated to helping you achieve your highest potential. With our holistic approach, chronic pain management strategies, personalized training, and group classes, we're here to support you every step of the way on your journey to wellness. Let us help you unlock your full potential and live a life filled with activity, purpose, and joy.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NDIS;
