import React from 'react';
import './Services.css';
import PersonalTrainingImage from '../assets/personaltraining.png';
import workerscompensation from '../assets/workerscompensation.png';
import backpain from '../assets/backpain.png';
import caraccidentinjuries from '../assets/caraccidentinjuries.png';
import poorposture from '../assets/poorposture.png';
import postoprehab from '../assets/postoprehab.png';
import muscularstrains from '../assets/muscularstrains.png';
import sportsperformance from '../assets/sportsperformance.png';
import serviceImage3 from '../assets/service3.png';
import massageImage from '../assets/massage.webp';
import ultrasoundImage from '../assets/ultrasound.png';
import shockwaveImage from '../assets/shockwave.png';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags

const Services = () => {
    return (
        <div>
{/* While we test GTM for now, we have removed the Google Analytics code from here */}

            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>OUR SERVICES</h1>
                    <h2>Comprehensive Physiotherapy and Wellness Solutions</h2>
                    <p>
                        At Ramp Physio, we offer a comprehensive range of physiotherapy and wellness services designed to help you achieve optimal health and performance. Our services include personal training, worker's compensation rehabilitation, back pain management, treatment for car accident injuries, posture correction, NDIS physiotherapy and gym services, post-operative rehabilitation, muscular strain treatment, sports performance enhancement, ultrasound therapy, EMS and shockwave therapy, remedial massage and much more. Our dedicated team is committed to providing personalized care tailored to your specific needs.
                    </p>
                </div>
            </div>
            <div className="services-container">
                <div className="service-item">
                    <Link to="/personaltraining">
                        <img src={PersonalTrainingImage} alt="PERSONAL TRAINING" className="service-image" />
                        <div className="service-info">
                            <h2>PERSONAL TRAINING</h2>
                        </div>
                    </Link>
                </div>
                <div className="service-item">
                    <Link to="/workerscompensation">
                        <img src={workerscompensation} alt="Worker's Compensation" className="service-image" />
                        <div className="service-info">
                            <h2>WORKER'S COMPENSATION</h2>
                        </div>
                    </Link>
                </div>
                <div className="service-item">
                    <Link to="/backpain">
                        <img src={backpain} alt="Back Pain" className="service-image" />
                        <div className="service-info">
                            <h2>BACK PAIN</h2>
                        </div>
                    </Link>
                </div>
                <div className="service-item">
                    <Link to="/caraccidentinjuries">
                        <img src={caraccidentinjuries} alt="Car Accident Injuries" className="service-image" />
                        <div className="service-info">
                            <h2>CAR ACCIDENT INJURIES</h2>
                        </div>
                    </Link>
                </div>
                <div className="service-item">
                    <Link to="/poorposture">
                        <img src={poorposture} alt="Poor Posture" className="service-image" />
                        <div className="service-info">
                            <h2>POOR POSTURE</h2>
                        </div>
                    </Link>
                </div>
                <div className="service-item">
                    <Link to="/ndis">
                        <img src={serviceImage3} alt="NDIS Physio and Gym Provider" className="service-image" />
                        <div className="service-info">
                            <h2>NDIS PHYSIO AND GYM PROVIDER</h2>
                        </div>
                    </Link>
                </div>
                <div className="service-item">
                    <Link to="/postoprehab">
                        <img src={postoprehab} alt="Post-Op Rehab" className="service-image" />
                        <div className="service-info">
                            <h2>POST-OP REHAB</h2>
                        </div>
                    </Link>
                </div>
                <div className="service-item">
                    <Link to="/muscularstrains">
                        <img src={muscularstrains} alt="Muscular Strains" className="service-image" />
                        <div className="service-info">
                            <h2>MUSCULAR STRAINS</h2>
                        </div>
                    </Link>
                </div>
                <div className="service-item">
                    <Link to="/sportsperformance">
                        <img src={sportsperformance} alt="Sports Performance" className="service-image" />
                        <div className="service-info">
                            <h2>SPORTS PERFORMANCE</h2>
                        </div>
                    </Link>
                </div>
                <div className="service-item">
                    <Link to="/ultrasound">
                        <img src={ultrasoundImage} alt="Ultrasound" className="service-image" />
                        <div className="service-info">
                            <h2>ULTRASOUND</h2>
                        </div>
                    </Link>
                </div>
                <div className="service-item">
                    <Link to="/ems">
                        <img src={shockwaveImage} alt="EMS and Shockwave Therapy" className="service-image" />
                        <div className="service-info">
                            <h2>EMS AND SHOCKWAVE THERAPY</h2>
                        </div>
                    </Link>
                </div>
                <div className="service-item">
                    <Link to="/remedialmassage">
                        <img src={massageImage} alt="Remedial Massage" className="service-image" />
                        <div className="service-info">
                            <h2>REMEDIAL MASSAGE</h2>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <p>
                        To find out more about our services and other news, please visit our{' '}
                        <a
                            href="https://www.rampphysioandfitness.com.au/news"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            News Page
                        </a>.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Services;
