import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForEpilepsy = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Epilepsy | Ramp Physio and Fitness</title>
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>NDIS Physiotherapy for Epilepsy: Enhancing Quality of Life</h1>
                    <h2>Explore how specialized NDIS physiotherapy can support individuals with epilepsy, focusing on tailored exercise programs and lifestyle modifications at Ramp Physio and Fitness.</h2>
                    <p>Epilepsy is a neurological condition that affects millions worldwide, and physiotherapy can play a crucial role in enhancing the quality of life for those living with epilepsy. At Ramp Physio and Fitness, we offer NDIS-supported physiotherapy services tailored to the unique needs of individuals with epilepsy. Our aim is to empower clients through personalized exercise regimes and lifestyle advice that align with their health and fitness goals, while also considering seizure management.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Epilepsy and Physiotherapy</h1>
                        <p>Epilepsy is characterized by unpredictable seizures and can cause other health complications. Physiotherapy under the NDIS can help manage these challenges by improving physical strength, balance, and coordination. Our physiotherapists at Ramp Physio and Fitness are trained to understand the complexities of epilepsy and provide safe, effective, and compassionate care.</p>

                        <p>Our services begin with a thorough assessment to understand each individual's condition, seizure patterns, and physical capabilities. We then design a customized physiotherapy program that not only aims to improve general fitness but also focuses on strategies to reduce the risk of injury during and after seizures.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_679609972.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Exercise Programs</h1>
                    <p>Exercise is beneficial for individuals with epilepsy, but it must be tailored to suit each person's needs and seizure patterns. At Ramp Physio and Fitness, we create personalized exercise programs that promote fitness while prioritizing safety. These programs may include strength training, cardiovascular exercises, and balance work, all designed to minimize the risk of seizure-related injuries.</p>

                    <p>We also educate clients on self-monitoring techniques during physical activity to ensure they remain safe while exercising. Our team works closely with clients to adjust their programs as needed, based on their response to exercise and changes in their health status.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        NDIS Support at Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Lifestyle Modifications for Epilepsy</h1>
                    <p>Living with epilepsy requires adjustments to daily life to manage seizures effectively. Our physiotherapists at Ramp Physio and Fitness provide guidance on lifestyle modifications that can complement medical treatments. These may include stress management techniques, sleep hygiene practices, and nutritional advice.</p>

                    <p>We understand that every individual's experience with epilepsy is different, and our approach is to work collaboratively with clients to find the best strategies that fit their lives. This holistic approach can lead to improved seizure control and a better overall quality of life.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Supporting Independence and Mobility</h1>
                        <p>Our goal at Ramp Physio and Fitness is to support the independence and mobility of individuals with epilepsy. Through targeted exercises and mobility training, we can help clients improve their ability to perform daily activities with greater ease and confidence.</p>
                        <p>By focusing on functional movements, we aim to enhance the independence of our clients, allowing them to lead more active and fulfilling lives. Our physiotherapists are dedicated to providing ongoing support and adapting programs to meet the evolving needs of each client.</p>

                        <br></br>
                        <p>
                            In summary, NDIS physiotherapy for epilepsy at Ramp Physio and Fitness is a multifaceted service that addresses the specific needs of individuals with epilepsy. Our expert team is committed to offering comprehensive care that encompasses personalized exercise programs, lifestyle modifications, and support for independence. We are here to assist you in achieving improved health outcomes and a higher quality of life.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForEpilepsy;