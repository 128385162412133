import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForPsychoticIllnesses = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>NDIS Physiotherapy for Psychotic Illnesses</h1>
                    <h2>Unlocking the Potential of Physiotherapy in Mental Health Recovery</h2>
                    <p>Psychotic illnesses present unique challenges that require holistic and individualized care. At Ramp Physio and Fitness, we recognize the critical role of physiotherapy in supporting mental health, particularly for individuals under the NDIS. Our specialized approach integrates physical treatment with mental health support, fostering a path to recovery that empowers participants to regain control over their lives. In this guide, we will delve into the transformative impact of NDIS physiotherapy for those living with psychotic illnesses.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Psychotic Illnesses</h1>
                        <p>Psychotic illnesses, such as schizophrenia and bipolar disorder, can significantly affect an individual's perception, thoughts, emotions, and behaviors. These conditions can lead to challenges with movement, coordination, and physical health, often exacerbated by medication side effects. Physiotherapy can play a pivotal role in addressing these physical challenges and improving quality of life.</p>
                        <p>Our NDIS physiotherapy services at Ramp Physio and Fitness are designed to cater to the unique needs of those with psychotic illnesses. By creating personalized treatment plans, we focus on enhancing mobility, building strength, and fostering independence in daily activities.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_679609972.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Benefits of Physiotherapy in Mental Health</h1>
                        <p>Integrating physiotherapy into the treatment plan for psychotic illnesses can lead to numerous benefits, including:
                            <br></br>
                            <br></br>
                            - Enhancing physical health and fitness<br></br>
                            - Improving coordination and balance<br></br>
                            - Reducing the risk of falls and injuries<br></br>
                            - Alleviating pain and discomfort<br></br>
                            - Promoting relaxation and stress relief<br></br>
                            - Encouraging social interaction and community participation<br></br>
                            - Building confidence and self-esteem<br></br>
                            - Supporting medication management through physical activity<br></br>
                            - Facilitating neuroplasticity and cognitive function<br></br>
                            - Assisting in the management of medication side effects<br></br>
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Empowering Through NDIS Support
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Personalized Treatment for Optimal Outcomes</h1>
                    <p>At Ramp Physio and Fitness, we believe that every NDIS participant deserves a program that is tailored to their specific needs. Our physiotherapists work closely with participants, their families, and support teams to develop targeted interventions that address both physical and psychological aspects of health.</p>
                    <p>We utilize a range of therapeutic techniques, including exercise prescription, manual therapy, and education on lifestyle modifications to enhance overall well-being. Our physiotherapy programs are crafted to be responsive and adaptable, ensuring that participants receive the most effective care at every stage of their recovery.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container5">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content5">
                        <h1>Collaborative Care and Support Networks</h1>
                        <p>Effective management of psychotic illnesses requires a collaborative approach. Our physiotherapists at Ramp Physio and Fitness are committed to working in partnership with mental health professionals, NDIS coordinators, and other healthcare providers to ensure a cohesive and comprehensive support network.</p>
                        <p>By fostering strong communication and collaboration, we aim to create a seamless continuum of care that addresses all facets of the participant's health and promotes sustained recovery and independence.</p>
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container6">
                <div className="service-intro-content6">
                    <h1>Overcoming Barriers to Physical Activity</h1>
                    <p>Individuals with psychotic illnesses often face barriers to engaging in regular physical activity, such as low motivation, fear of stigma, or physical limitations. Our physiotherapy services are designed to overcome these barriers by providing a safe, non-judgmental environment where participants can work towards their fitness goals at their own pace.</p>
                    <p>We emphasize the importance of incremental progress, celebrating each milestone to foster a sense of achievement and motivation to continue on the path to wellness.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/shutterstock_679609972.webp" alt="Supportive Environment" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="white" noMargin>
                <div className="service-intro-container7">
                    <div className="service-intro-content7">
                        <h1>Long-Term Wellness and Independence</h1>
                        <p>The ultimate goal of our NDIS physiotherapy services is to empower participants to achieve long-term wellness and independence. By providing the tools and support needed to maintain physical health, we help individuals with psychotic illnesses lead fulfilling lives and actively engage with their communities.</p>
                        <p>Our commitment to ongoing care and support ensures that participants can continue to access physiotherapy services as needed, adjusting to life's changes and challenges with resilience and confidence.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Long-Term Wellness" />
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForPsychoticIllnesses;