import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const Ndisphysiotherapyforautismspectrumdisorderasd = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering ASD Individuals Through NDIS Physiotherapy</h1>
                    <h2>Discover how NDIS-funded physiotherapy can significantly improve the quality of life for those with Autism Spectrum Disorder at Ramp Physio and Fitness.</h2>
                    <p>Autism Spectrum Disorder (ASD) presents unique challenges that affect an individual's motor skills, physical health, and social interaction. At Ramp Physio and Fitness, we are committed to providing specialized NDIS physiotherapy services tailored to meet the needs of individuals with ASD. Our goal is to enhance their physical abilities, promote independence, and support their participation in everyday activities. In this article, we'll delve into the ways our dedicated team utilizes physiotherapy to empower those with ASD and the role of NDIS funding in facilitating access to these essential services.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding ASD in Physiotherapy</h1>
                        <p>ASD is a complex neurodevelopmental condition that affects individuals differently, making personalized care essential. Physiotherapy for ASD addresses a range of motor deficits and sensory processing issues that can impact daily functioning. Our physiotherapists are trained to assess and create individualized programs that focus on improving coordination, muscle strength, balance, and motor planning.</p>

                        <p>Through the support of NDIS funding, individuals with ASD have access to our specialized services. These services are designed not only to enhance physical health but also to foster social skills and self-esteem through active participation in various physical activities.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_679609972.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="service-intro-content2">
                    <h1>Maximizing Potential with NDIS Support</h1>
                    <p>NDIS funding is a game-changer for individuals with ASD, providing financial support for physiotherapy services that are essential for their development. Our role is to help participants navigate their NDIS plans to ensure they receive the physiotherapy interventions that best suit their needs. We collaborate with NDIS planners and other healthcare professionals to create a cohesive approach to care.</p>

                    <p>Key areas we focus on include:</p>
                    <div>
                        - Enhancing gross motor skills for better mobility<br />
                        - Improving muscle tone and strength<br />
                        - Developing coordination and dexterity<br />
                        - Addressing sensory processing challenges<br />
                        - Promoting fitness and healthy lifestyle habits<br />
                        - Encouraging participation in community activities<br />
                    </div>
                </div>
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
            </div>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Collaborative and Holistic Approach</h1>
                    <p>Our physiotherapy services for ASD are not limited to physical interventions. We adopt a holistic approach that considers the emotional and social aspects of ASD. Collaborating with families, caregivers, and educators, we strive to integrate our therapeutic exercises into daily routines, making them a natural part of the individual's life.</p>

                    <p>We believe in the power of education and provide resources and support to empower our clients and their support networks. By doing so, we extend the benefits of physiotherapy beyond our sessions, fostering long-term improvements and independence.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Success Stories and Positive Outcomes</h1>
                        <p>At Ramp Physio and Fitness, we are proud of the positive changes we have witnessed in our clients with ASD. From increased participation in school activities to improved self-care and independence, the impact of physiotherapy is profound. We celebrate each milestone, whether it's a child taking their first independent steps or an adult engaging in community sports.</p>

                        <p>We invite you to read the testimonials from our clients and their families, which highlight the transformative power of NDIS physiotherapy for individuals with ASD.</p>

                        <br></br>
                        <p>
                            In conclusion, NDIS physiotherapy for ASD at Ramp Physio and Fitness is a vital service that supports individuals in reaching their full potential. Our team is passionate about delivering personalized care that makes a tangible difference in the lives of our clients. If you or someone you know could benefit from our services, please reach out to us to begin the journey towards improved health and well-being.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Ndisphysiotherapyforautismspectrumdisorderasd;