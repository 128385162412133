import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';
import './News.css';

const PhysiotherapyForSpecificConditions = () => {
  const articles = [
    { title: 'ACL', path: '/news/acl' },
    { title: 'ACL Injury Physiotherapy', path: '/news/aclinjuryphysiotherapy' },
    { title: 'ACL Tear', path: '/news/acltear' },
    { title: 'Ankylosing Spondylitis', path: '/news/physiotherapyforankylosingspondylitis' },
    { title: 'Arthritis Pain Management Physiotherapy', path: '/news/physiotherapyforarthritispainmanagement' },
    { title: 'Corrective Exercises for Posture', path: '/news/correctiveexercisesforposture' },
    { title: 'Deep Tissue Remedial Massage', path: '/news/deeptissueremedialmassage' },
    { title: 'Disc Bulges', path: '/news/discbulge' },
    { title: 'Dry Needling and Acupuncture', path: '/news/dryneedlingandacupuncture' },
    { title: 'EMS for Muscle Recovery', path: '/news/emsformusclerecovery' },
    { title: 'Facet Syndrome', path: '/news/facetsyndrometreatment' },
    { title: 'Frozen Shoulder', path: '/news/frozenshoulder' },
    { title: 'Knee Pain Physiotherapy', path: '/news/kneepainphysiotherapy' },
    { title: 'Lumbar Traction', path: '/news/lumbartraction' },
    { title: 'Manual Therapy Techniques', path: '/news/manualtherapytechniques' },
    { title: 'Neck Pain', path: '/news/neckpain' },
    { title: 'Physiotherapy for Back Pain', path: '/news/physiotherapyforbackpain' },
    { title: 'Physiotherapy for Bell\'s Palsy Recovery', path: '/news/physiotherapyforbellspalsyrecovery' },
    { title: 'Physiotherapy for Cerebral Palsy', path: '/news/physiotherapyforcerebralpalsy' },
    { title: 'Physiotherapy for Chronic Fatigue Syndrome (ME/CFS)', path: '/news/physiotherapyforchronicfatiguesyndromemecfs' },
    { title: 'Physiotherapy for Chronic Neck Tension and Headaches', path: '/news/physiotherapyforchronicnecktensionandheadaches' },
    { title: 'Physiotherapy for Chronic Obstructive Pulmonary Disease (COPD)', path: '/news/physiotherapyforchronicobstructivepulmonarydiseasecopd' },
    { title: 'Physiotherapy for Dupuytren\'s Contracture', path: '/news/physiotherapyfordupuytrenscontracture' },
    { title: 'Physiotherapy for Facial Nerve Palsy', path: '/news/physiotherapyforfacialnervepalsy' },
    { title: 'Physiotherapy for Flat Feet (Pes Planus)', path: '/news/physiotherapyforflatfeetpesplanus' },
    { title: 'Physiotherapy for Golfer\'s Elbow', path: '/news/physiotherapyforgolferselbow' },
    { title: 'Physiotherapy for Guillain-Barré Syndrome Recovery', path: '/news/physiotherapyforguillainbarrsyndromerecovery' },
    { title: 'Physiotherapy for Heel Spurs', path: '/news/physiotherapyforheelspurs' },
    { title: 'Physiotherapy for Hyperkyphosis (Hunchback)', path: '/news/physiotherapyforhyperkyphosishunchback' },
    { title: 'Physiotherapy for Juvenile Arthritis', path: '/news/physiotherapyforjuvenilearthritis' },
    { title: 'Physiotherapy for Labral Tears in the Hip', path: '/news/physiotherapyforlabraltearsinthehip' },
    { title: 'Physiotherapy for Lateral Epicondylitis (Tennis Elbow)', path: '/news/physiotherapyforlateralepicondylitistenniselbow' },
    { title: 'Physiotherapy for Morton\'s Neuroma', path: '/news/physiotherapyformortonsneuroma' },
    { title: 'Physiotherapy for Osteitis Pubis in Athletes', path: '/news/physiotherapyforosteitispubisinathletes' },
    { title: 'Physiotherapy for Patellar Dislocation', path: '/news/physiotherapyforpatellardislocation' },
    { title: 'Physiotherapy for Pelvic Pain', path: '/news/physiotherapyforpelvicpain' },
    { title: 'Physiotherapy for Peripheral Neuropathy', path: '/news/physiotherapyforperipheralneuropathy' },
    { title: 'Physiotherapy for Rheumatoid Arthritis', path: '/news/physiotherapyforrheumatoidarthritis' },
    { title: 'Physiotherapy for Runner\'s Knee (Patellofemoral Pain Syndrome)', path: '/news/physiotherapyforrunnerskneepatellofemoralpainsyndrome' },
    { title: 'Physiotherapy for Sacroiliac Joint Dysfunction', path: '/news/physiotherapyforsacroiliacjointdysfunction' },
    { title: 'Physiotherapy for Scheuermann\'s Disease', path: '/news/physiotherapyforscheuermannsdisease' },
    { title: 'Physiotherapy for Shoulder Impingement Syndrome', path: '/news/physiotherapyforshoulderimpingementsyndrome' },
    { title: 'Physiotherapy for Spinal Cord Injuries', path: '/news/physiotherapyforspinalcordinjuries' },
    { title: 'Physiotherapy for Thoracic Outlet Syndrome', path: '/news/physiotherapyforthoracicoutletsyndrome' },
    { title: 'Physiotherapy for Torticollis in Infants', path: '/news/physiotherapyfortorticollisininfants' },
    { title: 'Physiotherapy for Whiplash', path: '/news/physiotherapyforwhiplash' },
    { title: 'Physiotherapy for Whiplash-Associated Disorders', path: '/news/physiotherapyforwhiplashassociateddisorders' },
    { title: 'Physiotherapy Approaches to Treating Bursitis', path: '/news/physiotherapyapproachestotreatingbursitis' },
    { title: 'Physiotherapy Approaches to Treating Spondylolisthesis', path: '/news/physiotherapyapproachestotreatingspondylolisthesis' },
    { title: 'Physiotherapy for Sciatica', path: '/news/physiotherapyforsciatica' },
    { title: 'Sciatica Treatment', path: '/news/sciaticatreatment' },
    { title: 'Managing Sciatica', path: '/news/managingsciatica' },
    { title: 'Managing Sciatica with Physiotherapy Exercises', path: '/news/managingsciaticawithphysiotherapyexercises' },
    { title: 'TMJ Physiotherapy', path: '/news/tmjphysiotherapy' },
    { title: 'Degenerative Discs', path: '/news/degenerativediscs' },
    { title: 'Management of Chronic Pelvic Pain Syndrome', path: '/news/managementofchronicpelvicpainsyndrome' },
    { title: 'Managing Chronic Tendonitis with Physiotherapy', path: '/news/managingchronictendonitiswithphysiotherapy' },
    { title: 'Managing Hypermobility Syndrome with Physiotherapy', path: '/news/managinghypermobilitysyndromewithphysiotherapy' },
    { title: 'Managing Lymphedema with Physiotherapy', path: '/news/managinglymphedemawithphysiotherapy' },
    { title: 'Managing Multiple Sclerosis Symptoms with Physiotherapy', path: '/news/managingmultiplesclerosissymptomswithphysiotherapy' },
    { title: 'Managing Plantar Warts with Physiotherapy', path: '/news/managingplantarwartswithphysiotherapy' },
    { title: 'Managing Stress Incontinence with Physiotherapy', path: '/news/managingstressincontinencewithphysiotherapy' },
  ];

  const [searchQuery, setSearchQuery] = useState('');

  const fuse = new Fuse(articles, {
    keys: ['title'],
    threshold: 0.3,
  });

  const results = searchQuery ? fuse.search(searchQuery) : articles;
  const filteredArticles = searchQuery ? results.map(result => result.item) : articles;

  const half = Math.ceil(filteredArticles.length / 2);
  const firstColumnArticles = filteredArticles.slice(0, half);
  const secondColumnArticles = filteredArticles.slice(half);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="news-section">
      <div className="news-header">
        <h2>Physiotherapy For Specific Conditions</h2>
        <p>
          Stay informed with the latest news and updates from Ramp Physio and Fitness. Whether it's new services, health tips, or community events, you'll find it all here.
        </p>
        <input
          type="text"
          placeholder="Search articles..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="news-search"
        />
      </div>
      <div className="news-content">
        {/* First Column */}
        <div className="news-column">
          {firstColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < firstColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

        {/* Second Column */}
        <div className="news-column">
          {secondColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < secondColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PhysiotherapyForSpecificConditions;
