import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import fibroLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForFibromyalgia = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Effective Physiotherapy for Fibromyalgia</h1>
                    <h2>Unlock the potential of physiotherapy and targeted exercises to manage fibromyalgia symptoms and improve your quality of life at Ramp Physio and Fitness.</h2>
                    <p>Fibromyalgia is a chronic condition characterized by widespread pain, fatigue, and tender points throughout the body. At Ramp Physio and Fitness, we specialize in providing tailored physiotherapy treatments and exercises that can help alleviate fibromyalgia symptoms and enhance daily functioning. Our approach is rooted in evidence-based practices and a deep understanding of the condition's complexities. In this detailed guide, we'll share insights on how our physiotherapy techniques can offer relief and support for those living with fibromyalgia.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Fibromyalgia</h1>
                        <p>Fibromyalgia is not just about pain; it's a multifaceted syndrome that can disrupt sleep, mood, and energy levels. Physiotherapy treatment for fibromyalgia at Ramp Physio and Fitness begins with a thorough assessment to understand your unique symptom patterns, triggers, and lifestyle factors that may influence your condition.</p>

                        <p>We focus on educating our clients about fibromyalgia, empowering them with knowledge to make informed decisions about their treatment and self-management strategies. Our physiotherapists are committed to working alongside you to develop a customized plan that addresses your specific needs and goals.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_679609972.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Treatment Plans</h1>
                    <p>Our physiotherapy treatments for fibromyalgia are as unique as the individuals we serve. We combine manual therapy techniques, such as soft tissue massage and gentle joint mobilization, with tailored exercise programs to help reduce pain and stiffness.</p>

                    <p>Exercises are selected carefully to avoid exacerbating symptoms while progressively building strength and flexibility. We may incorporate low-impact aerobic exercises, which have been shown to improve cardiovascular health and reduce fibromyalgia pain, as well as targeted strengthening and stretching routines.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={fibroLogo} alt="Fibromyalgia Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Managing Symptoms Effectively</h1>
                    <p>Fibromyalgia symptoms can be unpredictable, but with the right strategies, they can be managed. We help you identify patterns in your symptoms and teach you how to modify activities to prevent flare-ups. Our physiotherapists also provide guidance on pain-relief techniques, including the use of heat or cold therapy and relaxation exercises.</p>

                    <p>Stress management is an integral part of treating fibromyalgia, as stress can often trigger or worsen symptoms. We offer techniques to help you manage stress and improve your overall well-being.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Exercise Guidance and Support</h1>
                    <p>At Ramp Physio and Fitness, we understand the importance of staying active for those with fibromyalgia. However, finding the right type and amount of exercise is crucial. Our physiotherapists will guide you through exercises that are appropriate for your condition, helping you to maintain mobility and function without overdoing it.</p>

                    <p>Here are 10 examples of exercises and activities we may recommend:</p>
                    <p>
                        - Gentle stretching routines <br/>
                        - Controlled breathing exercises <br/>
                        - Low-impact aerobic activities like walking or swimming <br/>
                        - Progressive muscle relaxation techniques <br/>
                        - Strength training with light resistance <br/>
                        - Aquatic therapy exercises <br/>
                        - Tai chi movements for balance and relaxation <br/>
                        - Guided imagery and other relaxation techniques <br/>
                        - Postural training to reduce muscle strain <br/>
                        - Functional mobility exercises to improve daily activities <br/>
                    </p>
                    <p>
                        Our team is committed to providing ongoing support, adjusting your exercise program as needed, and helping you stay motivated and on track with your fitness goals.
                    </p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForFibromyalgia;