import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForBipolarDisorder = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Empowering Lives: NDIS Physiotherapy for Bipolar Disorder</h1>
                    <h2>Explore the transformative power of physiotherapy for individuals with Bipolar Disorder under the NDIS at Ramp Physio and Fitness.</h2>
                    <p>Bipolar Disorder is a complex psychological condition that can significantly impact an individual's physical and mental well-being. At Ramp Physio and Fitness, we recognize the profound benefits that physiotherapy can offer to those living with Bipolar Disorder. As part of the National Disability Insurance Scheme (NDIS), our specialized physiotherapy services are designed to empower participants, enhance their quality of life, and support their journey towards achieving personal health and fitness goals. In this comprehensive guide, we will delve into the multifaceted aspects of NDIS physiotherapy for Bipolar Disorder and how our dedicated team can make a difference.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Bipolar Disorder</h1>
                        <p>Bipolar Disorder is characterized by significant fluctuations in mood, energy, and activity levels. These changes can range from periods of elated, energized behavior (manic episodes) to feelings of sadness and hopelessness (depressive episodes). The role of physiotherapy in managing Bipolar Disorder involves tailored exercises and therapeutic strategies aimed at improving physical health, which can positively influence mental health outcomes.</p>

                        <p>At Ramp Physio and Fitness, our initial assessment focuses on understanding each individual's specific needs and challenges. We work closely with NDIS participants to create a physiotherapy plan that aligns with their mental health care strategies, ensuring a holistic and integrated approach to wellness.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_679609972.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="service-intro-content2">
                    <h1>Personalized Physiotherapy Plans</h1>
                    <p>Our physiotherapy services for Bipolar Disorder are tailored to each participant's unique situation. We understand that mood fluctuations can affect motivation and physical abilities. Our team is trained to adapt each session to the participant's current state, providing gentle encouragement and professional support to ensure consistent progress.</p>

                    <p>Treatments may include structured exercise programs to improve cardiovascular health, strength training to build resilience, and flexibility exercises to enhance overall well-being. Each plan is designed to foster a sense of achievement and progress, crucial for building confidence and self-esteem in individuals with Bipolar Disorder.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
            </div>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Enhancing Quality of Life</h1>
                    <p>Our goal at Ramp Physio and Fitness is not only to address the physical aspects of Bipolar Disorder but also to enhance participants' overall quality of life. Regular physical activity has been shown to improve mood, reduce anxiety, and promote better sleep patterns, which are all beneficial for managing Bipolar Disorder.</p>

                    <p>We offer a supportive environment where NDIS participants can engage in physiotherapy without judgment. Our approach includes education on the importance of physical health in managing mental health conditions and encouragement to establish and maintain a healthy lifestyle.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Collaborative Care Approach</h1>
                        <p>Collaboration is key in the management of Bipolar Disorder. We work in conjunction with mental health professionals and NDIS coordinators to ensure that our physiotherapy interventions complement overall care plans. This multidisciplinary approach ensures that participants receive cohesive and consistent support throughout their journey.</p>
                        <p>Our physiotherapists are committed to open communication with all members of a participant's care team, providing updates on progress and adjusting physiotherapy plans as needed to align with mental health treatment goals.</p>

                        <br></br>
                        <p>
                            In conclusion, NDIS physiotherapy for Bipolar Disorder at Ramp Physio and Fitness is a holistic, empathetic, and empowering program. Our dedicated professionals are passionate about enhancing the lives of those we serve, leveraging physiotherapy as a powerful tool to support mental and physical health. We invite NDIS participants to join us on a path to improved well-being and a brighter, more balanced future.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForBipolarDisorder;