import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';
import './News.css';

const IntellectualAndDevelopmental = () => {
  const articles = [
    { title: 'NDIS Physiotherapy for Angelman\'s Syndrome', path: '/news/ndisphysiotherapyforangelmanssyndrome' },
    { title: 'NDIS Physiotherapy for Autism Spectrum Disorder (ASD)', path: '/news/ndisphysiotherapyforautismspectrumdisorderasd' },
    { title: 'NDIS Physiotherapy for Bardet-Biedi Syndrome', path: '/news/ndisphysiotherapyforbardetbiedisyndrome' },
    { title: 'NDIS Physiotherapy for Chromosomal Disorders', path: '/news/ndisphysiotherapyforchromosomaldisorders' },
    { title: 'NDIS Physiotherapy for Developmental Delay', path: '/news/ndisphysiotherapyfordevelopmentaldelay' },
    { title: 'NDIS Physiotherapy for Down Syndrome', path: '/news/ndisphysiotherapyfordownsyndrome' },
    { title: 'NDIS Physiotherapy for Foetal Alcohol Spectrum Syndrome', path: '/news/ndisphysiotherapyforfoetalalcoholspectrumsyndrome' },
    { title: 'NDIS Physiotherapy for Fragile X Syndrome', path: '/news/ndisphysiotherapyforfragilexsyndrome' },
    { title: 'NDIS Physiotherapy for Intellectual Disabilities', path: '/news/ndisphysiotherapyforintellectualdisabilities' },
    { title: 'NDIS Physiotherapy for Prader-Willi Syndrome', path: '/news/ndisphysiotherapyforpraderwillisyndrome' },
    { title: 'NDIS Physiotherapy for Williams Syndrome', path: '/news/ndisphysiotherapyforwilliamssyndrome' }
  ];

  const [searchQuery, setSearchQuery] = useState('');

  const fuse = new Fuse(articles, {
    keys: ['title'],
    threshold: 0.3,
  });

  const results = searchQuery ? fuse.search(searchQuery) : articles;
  const filteredArticles = searchQuery ? results.map(result => result.item) : articles;

  const half = Math.ceil(filteredArticles.length / 2);
  const firstColumnArticles = filteredArticles.slice(0, half);
  const secondColumnArticles = filteredArticles.slice(half);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="news-section">
      <div className="news-header">
        <h2>Understanding Physiotherapy</h2>
        <p>
          Stay informed with the latest news and updates from Ramp Physio and Fitness. Whether it's new services, health tips, or community events, you'll find it all here.
        </p>
        <input
          type="text"
          placeholder="Search articles..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="news-search"
        />
      </div>
      <div className="news-content">
        {/* First Column */}
        <div className="news-column">
          {firstColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < firstColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

        {/* Second Column */}
        <div className="news-column">
          {secondColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < secondColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IntellectualAndDevelopmental;
