import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import dryNeedlingLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForDryNeedling = () => {
    return (
        <div>
            <Helmet>
                <title>Dry Needling for Pain Relief and Recovery | Ramp Physio and Fitness</title>
                <meta name="description" content="Experience the benefits of dry needling with our expert physiotherapy treatment and exercises at Ramp Physio and Fitness. Book your session today!" />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Unlocking the Power of Dry Needling</h1>
                    <h2>Explore the transformative effects of dry needling in physiotherapy treatment and exercise at Ramp Physio and Fitness.</h2>
                    <p>Dry needling is a physiotherapy technique that can provide relief for muscular pain and stiffness. At Ramp Physio and Fitness, our qualified physiotherapists combine dry needling with tailored exercises to enhance your recovery and improve movement. Discover how this innovative treatment can be integrated into your wellness plan.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>The Science Behind Dry Needling</h1>
                        <p>Dry needling involves inserting a thin filament needle into the myofascial trigger points of muscles. The procedure aims to release knots and relieve muscle pain. The technique is often compared to acupuncture, but it is grounded in Western medicine principles and research.</p>

                        <p>Our physiotherapists at Ramp Physio and Fitness are trained in the latest dry needling techniques to ensure safe and effective treatment for our clients. The process begins with a thorough assessment to identify areas of tension and dysfunction that can benefit from dry needling.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_679609972.webp" alt="Dry Needling" />
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/massage.webp" alt="Dry Needling" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Benefits of Dry Needling</h1>
                        <p>Dry needling can offer a range of benefits, including:</p>
                        <div>
                            <p>• Reduction in muscle tension</p>
                            <p>• Alleviation of pain</p>
                            <p>• Enhanced movement and flexibility</p>
                            <p>• Accelerated recovery from injuries</p>
                            <p>• Improved circulation</p>
                            <p>• Decrease in inflammation</p>
                            <p>• Relief from headaches and migraines</p>
                            <p>• Assisting with chronic pain management</p>
                            <p>• Complementing other physiotherapy treatments</p>
                            <p>• Supporting athletic performance</p>
                        </div>
                    </div>
                </div>
            </SkewedContainer>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container3">
                    <img src={dryNeedlingLogo} alt="Dry Needling Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Integrating Dry Needling with Exercises</h1>
                    <p>Combining dry needling with specific exercises can significantly enhance rehabilitation outcomes. Our physiotherapists design exercise programs to complement the effects of dry needling, ensuring you receive a holistic approach to treatment.</p>

                    <p>Exercises may include stretching, strengthening, and functional movements that target the areas treated with dry needling. This combination not only helps with immediate pain relief but also contributes to long-term health and prevention of future injuries.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Targeting Specific Conditions</h1>
                        <p>Dry needling can be particularly effective for a variety of conditions, such as:</p>
                        <div>
                            <p>• Myofascial pain syndrome</p>
                            <p>• Tension-type headaches</p>
                            <p>• Lower back pain</p>
                            <p>• Tennis elbow</p>
                            <p>• Plantar fasciitis</p>
                            <p>• Sciatica</p>
                            <p>• Rotator cuff injuries</p>
                            <p>• Hip and gluteal pain</p>
                            <p>• Osteoarthritis of the knee</p>
                            <p>• Repetitive strain injuries</p>
                        </div>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForDryNeedling;