import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';
import './News.css';

const KeyTreatmentsForNDIS = () => {
  const articles = [
    { title: 'NDIS Physiotherapy Assisted stretching to relieve stiffness in joints', path: '/news/ndisphysiotherapyassistedstretchingtorelievestiffnessinjoints' },
    { title: 'NDIS Physiotherapy Electrotherapy and heat therapy to reduce inflammation', path: '/news/ndisphysiotherapyelectrotherapyandheattherapytoreduceinflammation' },
    { title: 'NDIS Physiotherapy Functional task training including balancing and movement', path: '/news/ndisphysiotherapyfunctionaltasktrainingincludingbalancingandmovement' },
    { title: 'NDIS Physiotherapy Improving mood and energy levels', path: '/news/ndisphysiotherapyimprovingmoodandenergylevels' },
    { title: 'NDIS Physiotherapy Strengthening exercises to boost muscle condition and posture', path: '/news/ndisphysiotherapystrengtheningexercisestoboostmuscleconditionandposture' },
    { title: 'NDIS Physiotherapy Tissues massages to break up tension in muscles', path: '/news/ndisphysiotherapytissuesmassagestobreakuptensioninmuscles' },
    { title: 'NDIS Physiotherapy Training capability in handling everyday actions and tasks', path: '/news/ndisphysiotherapytrainingcapabilityinhandlingeverydayactionsandtasks' },
    { title: 'NDIS Physiotherapy for NDIS Physiotherapy', path: '/news/ndisphysiotherapyforndisphysiotherapy' },
    { title: 'Physiotherapy Treatment and Exercises for Paediatric Physiotherapy', path: '/news/physiotherapytreatmentandexercisesforpaediatricphysiotherapy' },
    { title: 'Physiotherapy Treatment and Exercises for Post-Natal Physiotherapy', path: '/news/physiotherapytreatmentandexercisesforpostnatalphysiotherapy' },
    { title: 'Physiotherapy Treatment and Exercises for Post Surgical Rehabilitation', path: '/news/physiotherapytreatmentandexercisesforpostsurgicalrehabilitation' },
    { title: 'Physiotherapy Treatment and Exercises for Posture Correction', path: '/news/physiotherapytreatmentandexercisesforposturecorrection' },
    { title: 'Physiotherapy Treatment and Exercises for Pregnancy Care', path: '/news/physiotherapytreatmentandexercisesforpregnancycare' },
    { title: 'Physiotherapy Treatment and Exercises for Remedial Massage', path: '/news/physiotherapytreatmentandexercisesforremedialmassage' },
    { title: 'Physiotherapy Treatment and Exercises for Sciatica', path: '/news/physiotherapytreatmentandexercisesforsciatica' },
    { title: 'Physiotherapy Treatment and Exercises for What we treat', path: '/news/physiotherapytreatmentandexercisesforwhatwetreat' },
    { title: 'Physiotherapy Treatment and Exercises for Women\'s Health Physiotherapy', path: '/news/physiotherapytreatmentandexercisesforwomenshealthphysiotherapy' }
  ];

  const [searchQuery, setSearchQuery] = useState('');

  const fuse = new Fuse(articles, {
    keys: ['title'],
    threshold: 0.3,
  });

  const results = searchQuery ? fuse.search(searchQuery) : articles;
  const filteredArticles = searchQuery ? results.map(result => result.item) : articles;

  const half = Math.ceil(filteredArticles.length / 2);
  const firstColumnArticles = filteredArticles.slice(0, half);
  const secondColumnArticles = filteredArticles.slice(half);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="news-section">
      <div className="news-header">
        <h2>Understanding Physiotherapy</h2>
        <p>
          Stay informed with the latest news and updates from Ramp Physio and Fitness. Whether it's new services, health tips, or community events, you'll find it all here.
        </p>
        <input
          type="text"
          placeholder="Search articles..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="news-search"
        />
      </div>
      <div className="news-content">
        {/* First Column */}
        <div className="news-column">
          {firstColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < firstColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

        {/* Second Column */}
        <div className="news-column">
          {secondColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < secondColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KeyTreatmentsForNDIS;
