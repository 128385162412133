import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet';
import SignupForm from './utils/SignupForm';
import { Link } from 'react-router-dom';
import HealthSection from './utils/HealthSection';

const Beginnersguidetostartingagymroutine = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Beginner's Guide to Starting a Gym Routine</h1>
                    <h2>Embark on your fitness journey with confidence at Ramp Physio and Fitness. Our beginner's guide provides all the essentials to get you started on a gym routine that fits your lifestyle and goals.</h2>
                    <p>Embarking on a gym routine can be a daunting prospect for beginners. At Ramp Physio and Fitness, we understand the challenges that come with starting a new fitness regimen. This comprehensive guide is designed to help you navigate the gym environment, establish a solid routine, and set yourself up for success. Here, we'll cover everything from gym etiquette to workout plans specifically tailored for those new to the fitness world.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding the Gym Space</h1>
                        <p>Before diving into workouts, it's important to get familiar with the gym environment. Knowing where different equipment is located, understanding how to use the machines, and recognizing the gym's peak hours can enhance your experience. Our team at Ramp Physio and Fitness is always ready to give you a tour and answer any questions you might have.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/bike.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Creating Your Workout Plan</h1>
                    <p>Developing a workout plan that aligns with your goals is crucial. Whether you aim to lose weight, build strength, or improve overall fitness, our expert trainers can help craft a personalized plan. We'll introduce you to various exercises and ensure you perform them with proper form to maximize benefits and minimize injury risks.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Gym Etiquette and Safety</h1>
                    <p>Familiarizing yourself with gym etiquette will make your workouts more enjoyable and help maintain a positive atmosphere. Always wipe down equipment after use, re-rack weights, and be mindful of others' space. Safety is also paramount; never hesitate to ask for a spotter or assistance if you're uncertain about an exercise.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Nutrition and Hydration</h1>
                    <p>Exercise is only part of the equation; proper nutrition and hydration are key to achieving your fitness goals. Our team can provide guidance on healthy eating habits and the importance of staying hydrated, especially during workouts. A balanced diet complements your gym routine and supports your body's recovery process.</p>
                </div>
            </div>
            </SkewedContainer>
            <div className="service-intro-container5">
                <div className="service-intro-content5">
                    <h1>Tracking Progress and Adaptation</h1>
                    <p>Monitoring your progress is motivating and informative. It helps you understand how your body responds to exercise and when it's time to adjust your routine. At Ramp Physio and Fitness, we can help you set realistic goals and track your improvements, ensuring you stay motivated and on track.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/bike.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container6">
                    <h1>Rest and Recovery</h1>
                    <p>Rest days are essential for muscle recovery and overall wellbeing. Incorporating active recovery or complete rest into your routine can prevent burnout and injury. Our physiotherapists at Ramp Physio and Fitness can guide you on the best recovery practices and when to take a break.</p>
                </div>
            </SkewedContainer>
            <div className="service-intro-container7">
                <div className="service-intro-content7">
                    <h1>Community and Support</h1>
                    <p>Joining a community of like-minded individuals can boost your commitment and enjoyment of your gym routine. At Ramp Physio and Fitness, you'll find a supportive environment where members encourage each other and share their fitness journeys.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container8">
                    <div className="service-intro-content8">
                        <h1>Overcoming Challenges</h1>
                        <p>It's normal to face obstacles on your fitness journey. Whether it's a lack of motivation, plateaus, or time constraints, we're here to help you overcome these challenges and keep moving forward with your gym routine.</p>
                    </div>
                </div>
            </SkewedContainer>
            
    <SubpagesLink />
    <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Beginnersguidetostartingagymroutine;