import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyFunctionalTaskTrainingIncludingBalancingAndMovement = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Expert NDIS Physiotherapy Services</h1>
                    <h2>Discover tailored functional task training and physiotherapy under the NDIS at Ramp Physio and Fitness.</h2>
                    <p>Ramp Physio and Fitness provides specialized NDIS physiotherapy services, designed to empower participants with disabilities to achieve their individual health and movement goals. Our Homebush facility offers functional task training, including balance and movement exercises, to enhance daily living activities. With a focus on improving overall well-being, our physiotherapy team works closely with participants to create customized programs that align with their NDIS plans. Dive into the world of NDIS physiotherapy with us and see how we can support your journey to greater independence and fitness.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Personalized Functional Training</h1>
                        <p>Our NDIS physiotherapy begins with a personalized functional training plan, taking into account your unique needs and capabilities. The focus is on real-life activities that promote independence, such as walking, reaching, and transferring. We incorporate exercises that mimic daily tasks to ensure that each session is relevant and beneficial to your everyday life.</p>

                        <p>Balance and movement are foundational aspects of our training programs. By improving these skills, we aim to reduce the risk of falls and enhance mobility. Our physiotherapists utilize a variety of techniques, including proprioceptive exercises, strength training, and coordination activities, to foster stability and fluidity of movement.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_679609972.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Maximizing Movement Potential</h1>
                    <p>NDIS participants at Ramp Physio and Fitness receive comprehensive assessments to identify movement potential and areas for improvement. Our physiotherapists craft individualized programs that focus on enhancing functional abilities, whether it's for work, leisure, or self-care.</p>

                    <p>We understand the importance of movement in maintaining a healthy lifestyle and preventing secondary conditions. That's why we emphasize exercises that not only target specific disabilities but also contribute to overall physical fitness. Our goal is to help you move better, feel better, and live better.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness - Your NDIS Partner
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Strength and Conditioning Focus</h1>
                    <p>Strength and conditioning are vital components of NDIS physiotherapy at Ramp Physio and Fitness. We aim to build muscle strength, endurance, and power to support your functional tasks and activities. Our state-of-the-art gym facilities provide the perfect environment for tailored strength training programs.</p>

                    <p>Our knowledgeable physiotherapists guide you through each exercise, ensuring proper technique and safety. We progressively challenge your abilities, helping you to achieve new milestones in your fitness journey. Our approach is not only therapeutic but also empowering, as we celebrate each achievement along the way.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Collaborative Healthcare Approach</h1>
                        <p>At Ramp Physio and Fitness, we believe in a collaborative approach to healthcare. We work closely with NDIS coordinators, other healthcare professionals, and support networks to ensure a cohesive and comprehensive service delivery. Our communication lines are always open, allowing for seamless integration of physiotherapy into your broader care plan.</p>

                        <p>We also understand the importance of education and self-management. Our physiotherapists provide you with the knowledge and tools necessary to take control of your health, encouraging long-term adherence to exercise and wellness practices.</p>

                        <br></br>
                        <p>
                            Our commitment to your health and fitness is unwavering. As your NDIS physiotherapy provider, Ramp Physio and Fitness is dedicated to delivering high-quality care that meets your needs and exceeds your expectations. Let us be your partner in achieving a more active and fulfilling life.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyFunctionalTaskTrainingIncludingBalancingAndMovement;