import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyElectrotherapyAndHeatTherapyToReduceInflammation = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>NDIS Physiotherapy: Electrotherapy and Heat Therapy</h1>
                    <h2>Explore the benefits of electrotherapy and heat therapy in managing inflammation and enhancing recovery under NDIS physiotherapy at Ramp Physio and Fitness.</h2>
                    <p>At Ramp Physio and Fitness, we specialize in providing NDIS-approved physiotherapy services, including electrotherapy and heat therapy, to reduce inflammation and aid in the recovery process. Our expert team is dedicated to offering personalized treatment plans that cater to the unique needs of each participant. In this comprehensive guide, we will delve into how these therapies work, their benefits, and how they form an integral part of our physiotherapy approach.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Electrotherapy</h1>
                        <p>Electrotherapy involves the use of electrical energy to treat various conditions and injuries. It is an effective modality for reducing inflammation, managing pain, and promoting tissue healing. At Ramp Physio and Fitness, we use state-of-the-art electrotherapy equipment to deliver controlled electrical impulses to the affected areas, enhancing the natural healing process of the body.</p>

                        <p>Participants with diverse conditions can benefit from electrotherapy, including those with acute injuries, post-operative pain, or chronic inflammation. Our trained physiotherapists will assess your condition and determine the most suitable form of electrotherapy to support your recovery journey.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_679609972.webp" alt="Electrotherapy" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Heat Therapy" />
                </div>
                <div className="service-intro-content2">
                    <h1>The Role of Heat Therapy</h1>
                    <p>Heat therapy, also known as thermotherapy, is a non-invasive treatment that involves the application of heat to the body. It is particularly beneficial for relaxing muscles, increasing circulation, and soothing discomfort. At Ramp Physio and Fitness, we apply heat therapy using various methods such as warm packs, infrared heat, or hydrotherapy depending on the individual's needs.</p>

                    <p>Our physiotherapists will guide you through the process, ensuring that the heat is applied safely and effectively. This therapy is often used in conjunction with other treatment modalities to maximize therapeutic outcomes and improve overall well-being.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Combining Therapies for Optimal Results</h1>
                    <p>Integrating electrotherapy and heat therapy can provide synergistic effects, enhancing the benefits of each treatment. This combination approach can accelerate the recovery process, reduce treatment duration, and improve the quality of life for NDIS participants.</p>

                    <p>Our physiotherapists are skilled in combining these therapies with other rehabilitation techniques to create a comprehensive treatment plan tailored to your specific needs and rehabilitation goals.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Therapy Combination" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Patient Care" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Personalized NDIS Physiotherapy Plans</h1>
                        <p>Every NDIS participant at Ramp Physio and Fitness receives a personalized physiotherapy plan developed with their specific goals and needs in mind. Our therapists work closely with participants, their families, and support coordinators to ensure that the plan aligns with their NDIS goals.</p>
                        <p>Your personalized plan may include a combination of hands-on treatments, electrotherapy, heat therapy, and supervised exercises designed to improve function, increase independence, and enhance quality of life.</p>

                        <br></br>
                        <p>
                            We are committed to providing exceptional care and support throughout your physiotherapy journey. Our team is here to help you navigate the complexities of the NDIS and ensure you receive the high-quality treatment you deserve.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyElectrotherapyAndHeatTherapyToReduceInflammation;