import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';
import { Link } from 'react-router-dom'; // Import Link for routing

const Ndisphysiotherapyforpraderwillisyndrome = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Prader-Willi Syndrome | Ramp Physio and Fitness</title>
                <meta name="description" content="NDIS Physiotherapy for Prader-Willi Syndrome at Ramp Physio and Fitness provides specialized care tailored to the unique needs of individuals with PWS. Learn more about our services and book an appointment today." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Specialized NDIS Physiotherapy for Prader-Willi Syndrome</h1>
                    <h2>Empowering Individuals with Prader-Willi Syndrome through Tailored Physiotherapy and Fitness Programs</h2>
                    <p>Prader-Willi Syndrome (PWS) is a complex genetic condition requiring a multidisciplinary approach to care. Ramp Physio and Fitness is proud to offer specialized NDIS physiotherapy services to support individuals with PWS in Homebush, NSW. Our expert team is dedicated to providing personalized treatment plans that address the unique challenges and health concerns associated with PWS, promoting improved mobility, strength, and independence.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Prader-Willi Syndrome</h1>
                        <p>Prader-Willi Syndrome is a genetic disorder characterized by a range of physical, mental, and behavioral challenges. A hallmark of PWS is a chronic feeling of hunger that can lead to excessive eating and life-threatening obesity. Physiotherapy plays a crucial role in managing the symptoms of PWS, particularly in improving physical function and preventing complications.</p>

                        <p>Our NDIS-funded physiotherapy services for PWS at Ramp Physio and Fitness include comprehensive assessments to create customized, goal-oriented treatment plans. These are designed to enhance daily living activities, promote healthier weight management, and support overall well-being.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_679609972.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Exercise Programs</h1>
                    <p>Exercise is an integral component of managing PWS. Our physiotherapists develop personalized exercise programs that take into consideration the individual's abilities and limitations. These programs aim to improve cardiovascular health, increase muscle strength, and enhance flexibility and balance.</p>

                    <p>We focus on sustainable and enjoyable physical activities, ensuring that individuals with PWS can engage in exercise safely and consistently. Our programs also aim to foster a sense of achievement and autonomy, contributing to better overall quality of life.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Enhancing Motor Skills and Coordination</h1>
                    <p>Motor skill development is often delayed in individuals with Prader-Willi Syndrome. Our physiotherapists employ techniques to enhance gross and fine motor skills, coordination, and balance. This not only aids in everyday tasks but also supports safer mobility and reduces the risk of falls.</p>

                    <p>Through targeted exercises and hands-on therapy, we work to improve joint stability and movement patterns, which are essential for performing daily activities with greater ease and confidence.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Supporting Healthy Weight Management</h1>
                    <p>Weight management is a critical concern for individuals with PWS. Our physiotherapists collaborate with dietitians and other health professionals to create a holistic plan that addresses nutritional needs and promotes active living.</p>

                    <p>We provide guidance on low-impact exercises that are effective for burning calories and improving metabolism, taking into account the unique physiological aspects of PWS. Our goal is to support a sustainable lifestyle that balances energy intake with physical activity.</p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Ndisphysiotherapyforpraderwillisyndrome;