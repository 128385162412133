import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import physioLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForElbowPain = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Expert Elbow Pain Relief Strategies</h1>
                    <h2>Discover the comprehensive approach to elbow pain treatment and exercises at Ramp Physio and Fitness.</h2>
                    <p>Elbow pain can significantly hinder your daily activities and sports performance. At Ramp Physio and Fitness, we specialize in providing expert physiotherapy treatment and tailored exercises to alleviate elbow pain. Our dedicated team of professionals is committed to helping you overcome discomfort and regain full function of your elbow. In this article, we'll delve into the causes of elbow pain, the role physiotherapy plays in treatment, and the exercises that can aid in your recovery journey.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Elbow Pain Causes</h1>
                        <p>Elbow pain can arise from various factors, including overuse, trauma, or underlying medical conditions. Common causes include tennis elbow, golfer's elbow, bursitis, and arthritis. Identifying the root cause is crucial for effective treatment, and our physiotherapists are adept at diagnosing and formulating personalized management plans.</p>

                        <p>Our approach begins with a detailed assessment to understand your symptoms, medical history, and lifestyle. This information is essential in developing a targeted physiotherapy program that addresses not only the pain but also the contributing factors to prevent recurrence.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_679609972.webp" alt="Elbow Pain" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Physiotherapy" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Physiotherapy Interventions</h1>
                    <p>Our tailored physiotherapy treatments may include a combination of manual therapy, modalities, and therapeutic exercises. Techniques such as soft tissue massage, joint mobilization, and ultrasound therapy can help alleviate pain, reduce inflammation, and promote healing.</p>

                    <p>Exercises play a pivotal role in rehabilitation, aiming to strengthen the muscles around the elbow, improve flexibility, and enhance joint stability. Our physiotherapists will guide you through a series of exercises designed specifically for your condition and will closely monitor your progress to ensure optimal recovery.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={physioLogo} alt="Physio Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>

            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Effective Exercises for Elbow Pain</h1>
                    <p>Exercises tailored to your specific type of elbow pain can significantly aid in your recovery. Our physiotherapists can guide you through exercises such as wrist extensor stretches, isometric wrist exercises, and eccentric wrist strengthening. These exercises are designed to gradually increase the load through the tendon and muscles, promoting healing and preventing future injuries.</p>

                    <p>Here are 10 examples of exercises that may be included in your rehabilitation program:</p>
                    <p>• Wrist extensor stretch</p>
                    <p>• Wrist flexor stretch</p>
                    <p>• Isometric wrist extension</p>
                    <p>• Isometric wrist flexion</p>
                    <p>• Eccentric wrist extensor strengthening</p>
                    <p>• Eccentric wrist flexor strengthening</p>
                    <p>• Forearm pronation and supination</p>
                    <p>• Grip strengthening exercises</p>
                    <p>• Elbow flexion and extension</p>
                    <p>• Shoulder stabilization exercises</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Elbow Exercises" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Rehabilitation" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Comprehensive Recovery and Support</h1>
                        <p>Rehabilitation extends beyond the initial treatment phase. We provide ongoing support to ensure your elbow regains full strength and functionality. Our team is committed to educating you on injury prevention and self-management techniques to keep you performing at your best.</p>

                        <p>We also offer advice on ergonomic adjustments and activity modifications to facilitate a pain-free return to work, sports, and other activities. Our goal is to empower you with the knowledge and skills necessary to maintain your elbow health in the long term.</p>

                        <br></br>
                        <p>
                            In summary, Ramp Physio and Fitness is dedicated to delivering superior care for elbow pain through expert physiotherapy treatment and targeted exercises. We invite you to experience our personalized approach to rehabilitation and look forward to helping you achieve a swift and successful recovery.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForElbowPain;