import React from 'react';
import { APIProvider, Map, AdvancedMarker, Pin } from '@vis.gl/react-google-maps';

export default function SimpleMap() {
  const center = { lat: -33.862840, lng: 151.078820 };
  const mapId = '122d661e4f76a1cc';

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '50vh', width: '100%' }}>
      <APIProvider apiKey={'AIzaSyCTRov9-i71zL975QHDfgJyn12mvhwTpyU'} onLoad={() => console.log('Maps API has loaded.')}>
        <Map mapId={mapId} defaultZoom={13} defaultCenter={center}>
          <AdvancedMarker position={center}>
            <Pin />
          </AdvancedMarker>
        </Map>
      </APIProvider>
    </div>
  );
}
