import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import physiotherapyLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const PhysiotherapyTreatmentAndExercisesForSidePain = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Alleviating Side Pain with Physiotherapy</h1>
                    <h2>Discover effective physiotherapy treatments and exercises tailored to combat side pain at Ramp Physio and Fitness.</h2>
                    <p>Side pain can be a troubling and limiting condition, affecting our daily activities and overall quality of life. Whether it stems from a sports injury, an overuse injury, or a condition like hip bursitis, finding the right treatment and exercises is crucial for relief and recovery. At Ramp Physio and Fitness, we specialize in providing expert physiotherapy care to help you manage and alleviate side pain. Let's delve into the beneficial treatments and exercises that can aid in your journey to a pain-free life.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Side Pain Causes</h1>
                        <p>Side pain can arise from various sources, including muscle strains, rib fractures, and internal organ issues. Identifying the cause is the first step in creating an effective treatment plan. Our physiotherapists conduct thorough assessments to pinpoint the origin of your pain and tailor a recovery strategy suited to your specific condition.</p>

                        <p>Common causes of side pain include intercostal muscle strain, oblique muscle injuries, and issues with the lower ribs. Internal factors like kidney stones or gallbladder problems can also manifest as side pain. Once the cause is established, we can proceed with the most appropriate physiotherapy interventions.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_679609972.webp" alt="Exercise" />
                    </div>
                </div>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Physiotherapy Interventions for Relief</h1>
                    <p>Our physiotherapy treatments for side pain include manual therapies like massage and joint mobilizations, which help alleviate pain and improve mobility. We also use modalities such as ultrasound and electrical stimulation to reduce inflammation and promote healing.</p>

                    <p>Another key component of our treatment plan is therapeutic exercises. These exercises are designed to strengthen the muscles around the affected area, enhance flexibility, and support posture correction. By incorporating these targeted movements, we aim to not only relieve current pain but also prevent future occurrences.</p>
                </div>
            </div>
            </SkewedContainer>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={physiotherapyLogo} alt="Physiotherapy Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Exercise Prescription for Side Pain</h1>
                    <p>Exercise is a powerful tool in managing and reducing side pain. Our physiotherapists prescribe specific exercises that target the affected area, promote core stability, and improve overall body mechanics. Here are ten examples of exercises that may be included:</p>
                    <p>- Side Planks to enhance core stability</p>
                    <p>- Clamshells for hip strength</p>
                    <p>- Hip Abductor Strengthening</p>
                    <p>- Cat-Cow Stretch for spine flexibility</p>
                    <p>- Thoracic Rotations to improve rib mobility</p>
                    <p>- Pelvic Tilts for lower back and pelvic alignment</p>
                    <p>- Bird-Dog for core and back strength</p>
                    <p>- Child’s Pose for relaxation and stretch</p>
                    <p>- Diaphragmatic Breathing to reduce tension</p>
                    <p>- Foam Rolling for muscle release</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
                <div className="service-intro-container2">
                    <div className="intro-image">
                        <img src="../assets/Pinloaded.webp" alt="Exercise" />
                    </div>
                    <div className="service-intro-content2">
                        <h1>Long-term Management of Side Pain</h1>
                        <p>Our approach to side pain is not only about immediate relief but also about long-term management. We educate our clients on proper body mechanics and self-care techniques to maintain their health. We also offer guidance on activity modification to avoid aggravating the pain.</p>
                        <p>Our goal is to empower you with the knowledge and skills needed to manage your condition independently. This includes understanding when to rest, how to properly perform exercises, and recognizing the signs that indicate the need for further professional assistance.</p>

                        <br></br>
                        <p>
                            In conclusion, side pain can be a complex issue with various underlying causes, but with the right physiotherapy treatment and exercises, it can be effectively managed. At Ramp Physio and Fitness, we are dedicated to providing personalized care that addresses your specific needs and helps you return to your favorite activities without discomfort. Contact us today to start your journey to recovery.
                        </p>
                    </div>
                </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default PhysiotherapyTreatmentAndExercisesForSidePain;