import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';
import './News.css';

const Rehabilitation = () => {
  const articles = [
    { title: 'ACL Rehabilitation', path: '/news/aclrehab' },
    { title: 'ACL Surgery', path: '/news/aclsurgery' },
    { title: 'Rehabilitation After Ankle Fracture', path: '/news/rehabilitationafteranklefracture' },
    { title: 'Rehabilitation After Femur Fracture', path: '/news/rehabilitationafterfemurfracture' },
    { title: 'Rehabilitation After Hip Replacement Surgery', path: '/news/rehabilitationafterhipreplacementsurgery' },
    { title: 'Rehabilitation After Knee Replacement Surgery', path: '/news/rehabilitationafterkneereplacementsurgery' },
    { title: 'Rehabilitation After Meniscus Surgery', path: '/news/rehabilitationaftermeniscussurgery' },
    { title: 'Rehabilitation After Rotator Cuff Surgery', path: '/news/rehabilitationafterrotatorcuffsurgery' },
    { title: 'Rehabilitation After Shoulder Replacement Surgery', path: '/news/rehabilitationaftershoulderreplacementsurgery' },
    { title: 'Rehabilitation After Wrist Fracture', path: '/news/rehabilitationafterwristfracture' },
    { title: 'Rehabilitation Strategies for Muscle Atrophy', path: '/news/rehabilitationstrategiesformuscleatrophy' },
    { title: 'Rehabilitation Strategies for Spinal Stenosis', path: '/news/rehabilitationstrategiesforspinalstenosis' },
    { title: 'Rehabilitation Techniques for Achilles Tendon Rupture', path: '/news/rehabilitationtechniquesforachillestendonrupture' },
    { title: 'Spinal Decompression Therapy', path: '/news/spinaldecompressiontherapy' },
    { title: 'Shockwave Therapy', path: '/news/shockwavetherapy' },
    { title: 'Shockwave for Pain Relief', path: '/news/shockwavetherapyforpainrelief' },
    { title: 'Shockwave Therapy for Plantar Fasciitis', path: '/news/shockwavetherapyforplantarfasciitis' },
    { title: 'Sport Injury', path: '/news/sportsinjury' },
    { title: 'Sport Performance Enhancement', path: '/news/sportsperformanceenhancement' },
    { title: 'Sport Performance Physiotherapy', path: '/news/sportsperformancephysiotherapy' },
    { title: 'Ultrasound Physiotherapy', path: '/news/ultrasoundphysiotherapy' },
    { title: 'Physiotherapy Techniques for Managing Edema', path: '/news/physiotherapytechniquesformanagingedema' },
  ];

  const [searchQuery, setSearchQuery] = useState('');

  const fuse = new Fuse(articles, {
    keys: ['title'],
    threshold: 0.3,
  });

  const results = searchQuery ? fuse.search(searchQuery) : articles;
  const filteredArticles = searchQuery ? results.map(result => result.item) : articles;

  const half = Math.ceil(filteredArticles.length / 2);
  const firstColumnArticles = filteredArticles.slice(0, half);
  const secondColumnArticles = filteredArticles.slice(half);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="news-section">
      <div className="news-header">
        <h2>Rehabilitation</h2>
        <p>
          Stay informed with the latest news and updates from Ramp Physio and Fitness. Whether it's new services, health tips, or community events, you'll find it all here.
        </p>
        <input
          type="text"
          placeholder="Search articles..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="news-search"
        />
      </div>
      <div className="news-content">
        {/* First Column */}
        <div className="news-column">
          {firstColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < firstColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

        {/* Second Column */}
        <div className="news-column">
          {secondColumnArticles.map((article, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={article.path}>
                  <p>{article.title}</p>
                </Link>
              </div>
              {index < secondColumnArticles.length - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Rehabilitation;
