import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const Ndisphysiotherapyforangelmanssyndrome = () => {
    return (
        <div>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>NDIS Physiotherapy for Angelman's Syndrome</h1>
                    <h2>Expert Support for Angelman's Syndrome Through NDIS Physiotherapy</h2>
                    <p>Angelman's Syndrome is a genetic disorder that can significantly impact movement, balance, and coordination. At Ramp Physio and Fitness, our specialized NDIS physiotherapy services cater to individuals with Angelman's Syndrome, helping them navigate their unique challenges and enhance their quality of life. We are committed to providing personalized care that aligns with the goals and needs of each participant under the NDIS framework.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding Angelman's Syndrome</h1>
                        <p>Angelman's Syndrome is a neurogenetic condition characterized by developmental delays, speech impairments, and movement or balance issues. Physiotherapy plays a crucial role in managing these symptoms by improving motor function and promoting independence. Our physiotherapists are equipped with the knowledge and experience to support individuals with Angelman's Syndrome in their developmental journey.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_679609972.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Physiotherapy Approaches</h1>
                    <p>Every individual with Angelman's Syndrome has their own set of strengths and challenges. Our physiotherapists create customized treatment plans that target specific needs, such as enhancing gross motor skills, improving walking patterns, and increasing joint flexibility. We employ a variety of techniques, including strength training, balance exercises, and motor skill development, to promote optimal function.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Integrating NDIS Support</h1>
                    <p>We understand the importance of the National Disability Insurance Scheme (NDIS) in providing necessary funding for therapy services. Our team works closely with NDIS participants, their families, and their support coordinators to ensure that physiotherapy sessions are aligned with their NDIS plan goals. We aim to make the process seamless and stress-free, from the initial consultation to ongoing treatment.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Collaborative Care with Families</h1>
                    <p>Collaboration with families is key to the success of physiotherapy for individuals with Angelman's Syndrome. We encourage family involvement in therapy sessions to facilitate carryover of skills into the home environment. Our therapists also provide education and training to family members to empower them in supporting their loved one's development and progress.</p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />
            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default Ndisphysiotherapyforangelmanssyndrome;