import React from 'react';
import './ServiceDetail.css';
import SubpagesLink from './subpages_link';
import TestimonialSection from './TestimonialSection';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import SkewedContainer from 'sc-react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head tags
import SignupForm from './utils/SignupForm';
import HealthSection from './utils/HealthSection';

const NdisPhysiotherapyForFoetalAlcoholSpectrumSyndrome = () => {
    return (
        <div>
            <Helmet>
                <title>NDIS Physiotherapy for Foetal Alcohol Spectrum Syndrome | Ramp Physio and Fitness</title>
                <meta name="description" content="Expert NDIS physiotherapy services for Foetal Alcohol Spectrum Syndrome at Ramp Physio and Fitness. Tailored care to support individuals in Homebush, NSW." />
            </Helmet>
            <div className="service-detail-content">
                <div className="service-detail-text">
                    <br></br><br></br>
                    <h1>Comprehensive NDIS Physiotherapy for FASD</h1>
                    <h2>Unlocking Potential with Tailored Physiotherapy for Foetal Alcohol Spectrum Syndrome</h2>
                    <p>Foetal Alcohol Spectrum Syndrome (FASD) presents unique challenges that require specialized physiotherapy care. At Ramp Physio and Fitness, we are committed to providing expert NDIS physiotherapy services designed to support individuals with FASD. Our approach is centered on enhancing quality of life and maximizing functional abilities through personalized treatment plans and compassionate care.</p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
                <div className="service-detail-image-container">
                    <img src={serviceImage} alt="Service Detail" className="service-detail-image" />
                </div>
            </div>
            <SkewedContainer top="left" bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container1">
                    <div className="service-intro-content1">
                        <h1>Understanding FASD and Physiotherapy</h1>
                        <p>Foetal Alcohol Spectrum Syndrome is a complex condition that can affect coordination, movement, and muscle tone. Our physiotherapists are trained to understand the nuances of FASD and provide interventions that can help improve motor skills, balance, and strength. With a focus on functional independence, we aim to empower our clients through movement and active participation.</p>
                        <p>Our physiotherapy services are aligned with the National Disability Insurance Scheme (NDIS), ensuring that individuals with FASD have access to the support they need. We collaborate with NDIS planners and other healthcare professionals to create comprehensive care plans that address both immediate needs and long-term goals.</p>
                    </div>
                    <div className="intro-image">
                        <img src="../assets/shutterstock_679609972.webp" alt="Exercise" />
                    </div>
                </div>
            </SkewedContainer>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/massage.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Personalized Strategies for FASD</h1>
                    <p>At Ramp Physio and Fitness, we understand that every individual with FASD has unique needs. Our physiotherapists develop tailored strategies to enhance motor learning and promote independence. Through a variety of therapeutic exercises and activities, we focus on:</p>
                    <ul>
                        <li>Improving gross and fine motor skills</li>
                        <li>Enhancing balance and coordination</li>
                        <li>Strengthening muscles and improving tone</li>
                        <li>Increasing joint mobility and flexibility</li>
                        <li>Promoting sensory integration and processing</li>
                        <li>Facilitating neurodevelopmental growth</li>
                    </ul>
                    <p>Our goal is to provide holistic support that goes beyond physical therapy, involving education and resources for families to continue progress at home.</p>
                </div>
            </div>
            <SkewedContainer bottom="left" bgColor="white" noMargin>
                <div className="service-intro-container3">
                    <img src={ndisLogo} alt="NDIS Logo" className="service-intro-logo" />
                    <p className="service-intro-text">
                        Ramp Physio and Fitness
                    </p>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="servicedetail-book-now"
                    >
                        Book Now
                    </a>
                </div>
            </SkewedContainer>
            <div className="service-intro-container4">
                <div className="service-intro-content4">
                    <h1>Collaborative Care and NDIS Support</h1>
                    <p>Collaboration is key in managing FASD effectively. Our physiotherapists work closely with NDIS support coordinators, occupational therapists, speech therapists, and other professionals to deliver comprehensive care. We assist in navigating the NDIS process, advocating for our clients to receive the appropriate funding for physiotherapy services that can make a real difference.</p>
                    <p>Our team is well-versed in the challenges that individuals with FASD and their families may face when seeking support. We are here to guide you through each step, ensuring that the focus remains on achieving the best possible outcomes.</p>
                </div>
                <div className="intro-image">
                    <img src="../assets/Gymfloor1.webp" alt="Exercise" />
                </div>
            </div>
            <SkewedContainer top="left" bgColor="#2980b9" noMargin>
            <div className="service-intro-container2">
                <div className="intro-image">
                    <img src="../assets/Pinloaded.webp" alt="Exercise" />
                </div>
                <div className="service-intro-content2">
                    <h1>Empowering Independence Through Movement</h1>
                    <p>Independence is a critical goal for individuals with FASD. Our physiotherapy programs are designed to empower clients through movement, helping them to achieve greater autonomy in their daily lives. We focus on practical skills that can be transferred to real-world settings, such as school, work, and community participation.</p>
                    <p>Through consistent and compassionate care, we strive to enhance the lives of those with FASD, providing them with the tools and confidence to move forward with strength and determination.</p>
                    <br></br>
                    <p>In conclusion, our dedicated team at Ramp Physio and Fitness is committed to delivering exceptional NDIS physiotherapy services for individuals with Foetal Alcohol Spectrum Syndrome. We believe in the transformative power of physiotherapy to improve quality of life and foster independence. If you or a loved one is navigating the challenges of FASD, we are here to support you every step of the way.</p>
                </div>
            </div>
            </SkewedContainer>
            <SubpagesLink />

            <HealthSection />
            <TestimonialSection />
        </div>
    );
};

export default NdisPhysiotherapyForFoetalAlcoholSpectrumSyndrome;