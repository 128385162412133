import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import emailjs from 'emailjs-com';
import './Home.css';
import TestimonialSection from './TestimonialSection';
import SkewedContainer from 'sc-react';
import { FaPhoneAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// Initialize the modal
Modal.setAppElement('#root');

const Home = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    });
    const [showFloatingButton, setShowFloatingButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const introSection = document.querySelector('.intro');
            const footerBar = document.querySelector('.footer-bar');
            if (introSection && footerBar) {
                const introPosition = introSection.getBoundingClientRect().top;
                const footerPosition = footerBar.getBoundingClientRect().top;
                const screenPosition = window.innerHeight / 2;

                if (introPosition < screenPosition && footerPosition > window.innerHeight) {
                    setShowFloatingButton(true);
                } else {
                    setShowFloatingButton(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phone: formData.phone,
        };

        emailjs.send('service_gvt5vwp', 'template_nwga3kh', templateParams, 'OpJMLUp7AgSF4zW4L')
            .then((response) => {
                alert('Form submitted successfully!');
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                });
            }, (error) => {
                alert('There was an error submitting the form.');
                console.error('Failed to send email:', error);
            });
    };

    return (
        <div>
            <Helmet>
                <meta name="description" content="Looking for expert physio near you? Our clinic offers top-tier sports injury management, physiotherapy, healthcare, and personalized training. Explore services like NDIS support, chronic pain management, and group training classes. Achieve your health and fitness goals with us today." />
                <title>RAMP</title>
                  {/* Google tag (gtag.js) */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-P52MNG23ES"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-P52MNG23ES');
                    `}
                </script>
            </Helmet>
            <div className="home">
                <div className="text-box">
                    <h1>RAMP PHYSIOTHERAPY AND PERSONAL TRAINING</h1>
                    <button onClick={openModal}>Call us <FaPhoneAlt style={{ marginRight: '2px' }} />now for a same day appointment guaranteed！</button>
                    <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cover-book-now"
                        >
                        Book Now
                    </a>
                </div>
            </div>
            <div className="intro">
                <div className="intro-content">
                    <h1>REVOLUTIONARY APPROACH: INTEGRATING PHYSIOTHERAPY, PERSONAL TRAINING AND HEALTHCARE.</h1>
                    <p>We believe in the transformative power of physiotherapy combined with RAMP personal training exercise program. Our unique approach ensures effective management strategies for injury rehabilitation and fitness. Whether you're seeking support for NDIS, workers compensation, improving your fitness and health, sports injury rehabilitation, or chronic condition management, our team of physiotherapy experts and personal trainers is here to guide you. Combining physiotherapy with RAMP personal training, we help you reach your health and fitness goals while improving your overall well-being.
                    </p>
                </div>
                <div className="intro-video">
                    <iframe
                        width="100%"
                        height="600"
                        src="https://www.youtube.com/embed/SdBHcech9Js"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>


            </div>
            <SkewedContainer top="left" bottom="left" bgColor="#009fe3" noMargin>
                <div className="services-section">
                    <div className="services-header">
                        <h2>OUR SERVICES</h2>
                    </div>
                    <div className="services-content">
                        <div className="service-item">
                        <Link to="/services">
                            <img src="../assets/PHYSIOTHERAPY.webp" alt="Physiotherapy" />
                            <h3>PHYSIOTHERAPY</h3>
                            </Link>
                        </div>
                        <div className="service-item">
                        <Link to="/caraccidentinjuries">
                            <img src="../assets/CHRONIC PAIN MANAGEMENT.webp" alt="Chronic Pain Management" />
                            <h3>CTP</h3>
                            </Link>
                        </div>
                        <div className="service-item">
                        <Link to="/ndis">
                            <img src="../assets/NDIS.webp" alt="NDIS" />
                            <h3>NDIS</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="services-content">
                        <div className="service-item">
                        <Link to="/workcovernsw">
                            <img src="../assets/Group train.webp" alt="GROUP TRAINING CLASSES" />
                            <h3>WORKCOVER</h3>
                            </Link>
                        </div>
                        <div className="service-item">
                        <Link to="/personaltraining">
                            <img src="../assets/per train.webp" alt="PERSONAL TRAINING" />
                            <h3>PERSONAL TRAINING</h3>
                            </Link>
                        </div>
                        <div className="service-item">
                        <Link to="/remedialmassage">
                            <img src="../assets/massage.webp" alt="REMEDIAL MASSAGE" />
                            <h3>REMEDIAL MASSAGE</h3>
                            </Link>
                        </div>

                    </div>
                </div>
            </SkewedContainer>
            <div className="discover-image">
            </div>
            <SkewedContainer top="left" bgColor="#002b54" noMargin>
                <div className="amenities-section">
                    <div className="amenities-header">
                        <h2>OUR SERVICES</h2>
                        <p>At Ramp Physio and Fitness we provide specialised treatment programs. If you would like to learn more please call us or submit a contact us request through this website.</p>
                    </div>
                    <div className="amenities-content">
                        <div className="amenities-column">
                            <div className="amenities-item">
                           <Link to="/personaltraining">
                                <p>Personal Training</p>
                                </Link>
                            </div>
                            <div className="amenities-item">
                                <p>•</p>
                            </div>
                            <div className="amenities-item">
                            <Link to="/backpain">
                                <p>Back pain</p>
                                </Link>
                            </div>
                            <div className="amenities-item">
                                <p>•</p>
                            </div>
                            <div className="amenities-item">
                            <Link to="/workerscompensation">
                                <p>Work-related injuries</p>
                                </Link>
                            </div>
                            <div className="amenities-item">
                                <p>•</p>
                            </div>
                            <div className="amenities-item">
                            <Link to="/caraccidentinjuries">
                                <p>Car accident injuries</p>
                                </Link>
                            </div>
                            <div className="amenities-item">
                                <p>•</p>
                            </div>
                            <div className="amenities-item">
                            <Link to="/poorposture">
                                <p>Poor posture</p>
                                </Link>
                            </div>
                            <p>•</p>
                            <div className="amenities-item">
                            <Link to="/postoprehab">
                                <p>Post-op rehab </p>
                                </Link>
                            </div>
                        </div>
                        <div className="amenities-column">
                            <div className="amenities-item">
                            <Link to="/ndis">
                                <p>NDIS physio and gym provider</p>
                                </Link>
                            </div>
                            <div className="amenities-item">
                                <p>•</p>
                            </div>
                            <div className="amenities-item">
                            <Link to="/postoprehab">
                                <p>Post-op rehabilitation</p>
                                </Link>
                            </div>
                            <div className="amenities-item">
                                <p>•</p>
                            </div>
                            <div className="amenities-item">
                            <Link to="/muscularstrains">
                                <p>Muscular strains</p>
                                </Link>
                            </div>
                            <div className="amenities-item">
                                <p>•</p>
                            </div>
                            <div className="amenities-item">
                            <Link to="/sportsperformace">
                                <p>Sports performance</p>
                                </Link>
                            </div>
                            <div className="amenities-item">
                                <p>•</p>
                            </div>
                            <div className="amenities-item">
                            <Link to="/sportsperformance">
                                <p>Sports injuries</p>
                                </Link>
                            </div>
                            <p>•</p>
                            <div className="amenities-item">
                            <Link to="/news">
                                <p>Find out more</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </SkewedContainer>
            <div className="health-section">
                <div className="health-content">
                    <h2>TAKE CONTROL OF YOUR HEALTH!</h2>
                    <p>Book a consultation to improve your well-being and become pain free.</p>
                </div>
                <div className="health-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="first-name">Name <span>(required)</span></label>
                            <div className="form-group-inline">
                                <input
                                    type="text"
                                    id="first-name"
                                    name="firstName"
                                    placeholder="First Name"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                />
                                <input
                                    type="text"
                                    id="last-name"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email <span>(required)</span></label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Phone <span>(required)</span></label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                placeholder="Phone"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
            <TestimonialSection />
            {/* Floating Action Button */}
            {!modalIsOpen && showFloatingButton && (
                <div className="floating-bar">
                    <img src="../assets/ramp-icon-update.png" alt="Logo" className="floating-logo" />
                    <button className="floating-button" onClick={openModal}>
                        <FaPhoneAlt style={{ marginRight: '2px' }} /> Contact Us
                    </button>

                </div>

            )}
            {/* Modal Component */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Phone Number"
                className="modal"
                overlayClassName="overlay"
            >
                <h2>Contact Us</h2>
                <a href="tel:+61281881618" className="nav-link">PH: (02) 8188 1618</a>
                <button onClick={closeModal}>Close</button>
            </Modal>
        </div>
    );
};

export default Home;
