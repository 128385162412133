import React, { useState } from 'react';
import { FaInstagram, FaYoutube, FaFacebookF } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <header className="header">
      <Link to="/" className="nav-logo"></Link>
      <div className="nav-clinic-container mobile-phone-number">
            <a href="tel:+61281881618" className="nav-link" onClick={closeMobileMenu}>PH: (02) 8188 1618</a>
            <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
              target="_blank"
              rel="noopener noreferrer"
              className="book-now"
              onClick={closeMobileMenu}>
              Book Now
            </a>
          </div>
      <nav className={`nav-links ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
        {isMobileMenuOpen && (
          <div className="mobile-menu-logo">
            <img src="../assets/ramp-icon-update.png" alt="Logo" className="mobile-logo" />
          </div>
        )}
        <div className="nav-left">
          <ul>
            <li><Link to="/" className="nav-link" onClick={closeMobileMenu}>Home</Link></li>
            <li><Link to="/services" className="nav-link" onClick={closeMobileMenu}>Services</Link></li>
            <li><Link to="/about" className="nav-link" onClick={closeMobileMenu}>About</Link></li>
            <li><Link to="/team" className="nav-link" onClick={closeMobileMenu}>Team</Link></li>
            <li><Link to="/contact" className="nav-link" onClick={closeMobileMenu}>Contact</Link></li>
          </ul>
        </div>
        <div className="nav-right">
          <div className="nav-clinic-container">
            <a href="tel:+61281881618" className="nav-link" onClick={closeMobileMenu}>PH: (02) 8188 1618</a>
            <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
              target="_blank"
              rel="noopener noreferrer"
              className="book-now"
              onClick={closeMobileMenu}>
              Book Now
            </a>
          </div>
          <a href="https://www.instagram.com/rampphysioandfitness/" target="_blank" rel="noopener noreferrer" className="nav-icon" onClick={closeMobileMenu}>
            <FaInstagram />
          </a>
          <a href="https://youtube.com/@RampPhysioAndFitnessHomebush" target="_blank" rel="noopener noreferrer" className="nav-icon" onClick={closeMobileMenu}>
            <FaYoutube />
          </a>
          <a href="https://www.facebook.com/share/MYK3qD2sDntdHvaE/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" className="nav-icon" onClick={closeMobileMenu}>
            <FaFacebookF />
          </a>
          <Link to="/contact" className="join-now" onClick={closeMobileMenu}>Contact Us</Link>
        </div>
      </nav>
      <button className="menu-toggle" onClick={toggleMobileMenu}>
        {isMobileMenuOpen ? '✕' : '☰'}
      </button>
    </header>
  );
};

export default Header;