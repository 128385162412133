import React from 'react';
import serviceImage from '../assets/service1.png';
import ndisLogo from '../assets/ramp-icon-update.png';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';
import './News.css';

const News = () => {
  const mainCategories = [
    {
      title: 'Understanding Physiotherapy',
      path: '/news/understandingphysiotherapy',
    },
    {
      title: 'Physiotherapy for Specific Conditions',
      path: '/news/physiotherapyforspecificconditions',
    },
    {
      title: 'Rehabilitation',
      path: '/news/rehabilitation',
    },
    {
      title: 'Gym Knowledge Base',
      path: '/news/gymknowledge',
    },
    {
      title: 'Benefits of Gym Training',
      path: '/news/benefitsofgymtraining',
    },
    {
      title: 'NDIS Knowledge',
      path: '/news/ndisknowledge',
    }
  ];

  // Search functionality Commented out for now as it's not working
  // const [searchQuery, setSearchQuery] = useState('');

  // const fuse = new Fuse(articles, {
  //   keys: ['title'],
  //   threshold: 0.3,
  // });

  // const results = searchQuery ? fuse.search(searchQuery) : articles;
  // const filteredArticles = searchQuery ? results.map(result => result.item) : articles;

  // const handleSearchChange = (e) => {
  //   setSearchQuery(e.target.value);
  // };

  return (
    <div className="news-section">
      <div className="news-header">
        <h2>Physiotherapy & Gym Training Knowledge</h2>
        <p>
          Stay informed with the latest news and updates from Ramp Physio and Fitness. Whether it's new services, health tips, or community events, you'll find it all here.
        </p>
        {/* Search functionality moved to individual category pages for better content filtering */}
      </div>
      <div className="news-content">
        {/* First Column */}
        <div className="news-column">
          {mainCategories.slice(0, Math.ceil(mainCategories.length / 2)).map((category, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={category.path}>
                  <p>{category.title}</p>
                </Link>
              </div>
              {index < Math.ceil(mainCategories.length / 2) - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

        {/* Second Column */}
        <div className="news-column">
          {mainCategories.slice(Math.ceil(mainCategories.length / 2)).map((category, index) => (
            <React.Fragment key={index}>
              <div className="news-item">
                <Link to={category.path}>
                  <p>{category.title}</p>
                </Link>
              </div>
              {index < Math.floor(mainCategories.length / 2) - 1 && (
                <div className="news-item-separator">
                  <p>•</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <br></br>
      <div className="news-section">
        <a 
          href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
          target="_blank"
          rel="noopener noreferrer"
          className="servicedetail-book-now"
        >
          Book Now
        </a>
      </div>
    </div>
  );
};

export default News;
